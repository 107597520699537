import React, { useEffect, useRef, useState } from 'react';
import Messenger from './Loader';
import $ from 'jquery';

const Opening = () => {
  const [opacity, setOpacity] = useState(true);
  const messengerRef = useRef(null);

  useEffect(() => {
    const el = $(messengerRef.current);
    const messenger = new Messenger(el);
    
    setTimeout(() => {
      setOpacity(false); // Change opacity to 0
    }, 2000);

    return () => {
      el.html(''); // Cleanup Messenger content
    };
  }, []);

  return (
    <div
      className={`transition duration-300 flex justify-center items-center text-center w-screen h-screen overflow-hidden fixed top-0 z-50 bg-midnight select-none ${opacity ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className='text-white text-3xl desktop:text-7xl font-sans font-bold' id="messenger" ref={messengerRef}></div>
    </div>
  );
};

export default Opening;
