import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Tooltip } from "react-tooltip";

export default function Events() {

    const nav = useNavigate();

    useEffect(() => {
        document.title = "Events - HIMSTAT";
        Aos.init();
    }, []);

    const [isLoading, setIsLoading] = useState(true);

    const [eventsList, setEventsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    // Search
    const [searchText, setSearchText] = useState('');
    const [inputValue, setInputValue] = useState('');

    const getEvents = useCallback( async () => {
        setIsLoading(true);
        try {
            if(searchText.length > 0){
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/events?search=${searchText}`);
                const {events} = await response.data;
                return events;
            }
            else{
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/events?page=${currentPage}`);
                const {events} = await response.data;
                return events;
            }
        } catch (error) {
            nav('/');
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, searchText, nav]);

    const handleSearch = (event) => {
        event.preventDefault();
        setSearchText(inputValue);
    }

    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const handlePageChange = (data) => {
        setCurrentPage(data.selected + 1);
        window.scrollTo({top: 0});
    };

    useEffect(() => {
        getEvents().then((results) => {
            if(results) {
                setEventsList(results.data);
                setPageCount(results["last_page"]);
            }
        });
    }, [currentPage, getEvents]);

    return(
        <div className="flex flex-col items-center my-20 gap-12 mx-4 desktop:mx-60">
            <div className="flex flex-col gap-4 items-center">
                <h1 data-aos="fade-up" className="text-white text-3xl desktop:text-7xl font-bold select-none">OUR EVENTS</h1>
                {eventsList.length > 0 && <form data-aos="fade-up" onSubmit={handleSearch} className="flex justify-between items-center py-1.5 px-5 bg-white rounded-full">
                    <input className="bg-transparent focus:outline-none w-11/12" type="text" placeholder="Search" value={inputValue} onChange={(e) => setInputValue(e.target.value)} name="searchInput"/>
                    <button><i class="fa-solid fa-magnifying-glass"></i></button>
                </form>}
            </div>
            {isLoading ? <div className="text-white"><p>Loading...</p></div> : 
            eventsList.length > 0 ? <div className="grid grid-cols-1 desktop:grid-cols-3 gap-2 desktop:gap-8">
                {eventsList.map((event) => (
                    <Link to={`/events/${event.EventId}`} 
                    data-aos="fade-up"
                    className="group flex flex-row desktop:flex-col gap-2 desktop:gap-6 rounded-2xl text-midnight font-sans p-2.5 desktop:p-6 bg-white">
                        <img className="rounded-lg object-cover w-1/4 desktop:w-full max-h-12 min-w-20 self-center desktop:max-h-full" src={process.env.REACT_APP_BASE_URL + "/storage/EventStorage/Thumbnail/" + event.Thumbnail} alt="Thumbnail"/>
                        <div className="flex flex-col gap-2 justify-between w-3/4 desktop:w-full">
                            <h2 
                            data-tooltip-id="event-tooltip"
                            data-tooltip-content={event.Title}
                            data-tooltip-delay-show={500}
                            data-tooltip-variant="dark"
                            data-tooltip-place="bottom"
                            data-tooltip-delay-hide={0}
                            className="font-semibold text-base desktop:text-xl truncate">
                                {event.Title}
                            </h2>
                            <p className="text-sm desktop:text-base">{formatDate(event.DateCreated)}</p>
                        </div>
                    </Link>
                ))}
                <Tooltip id="event-tooltip" />
            </div> :
            <div className="text-white">
                <h2 className="text-xl font-semibold">No Events Available</h2>
                <p className="mt-4">It looks like there are no events available at the moment. Check back later or explore other sections of our site.</p>
            </div>
            }

            {searchText.length === 0 && eventsList.length > 0 && <ReactPaginate
                previousLabel=""
                nextLabel=""
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                containerClassName="flex gap-4 justify-center items-center"
                previousClassName="text-white"
                previousLinkClassName="fa-solid fa-arrow-left"
                nextClassName="text-white"
                nextLinkClassName="fa-solid fa-arrow-right"
                pageClassName="rounded-full w-[40px] h-[40px] flex justify-center items-center text-white font-semibold"
                pageLinkClassName="rounded-full border-2 border-white w-[40px] h-[40px] flex justify-center items-center hover:bg-white/30 transition-all"
                breakClassName="text-white"
                activeClassName="bg-white"
                activeLinkClassName="text-midnight hover:none"
                onPageChange={handlePageChange}
            />}
        </div>
    );
};