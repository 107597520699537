import { useEffect } from 'react';
import { dpi, lrd, hrdc, et, mic, vcd, pr, web } from '../Components/DataOfStructure';
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function Structure(){

    const structureList = [
        'LRD',
        'Executive Board',
        'MIC'
    ];

    useEffect(() => {
        document.title = "About - HIMSTAT";
        Aos.init();
    }, []);

    // Buat nentuin berapa banyak orang di setiap row kalo orangnya lebih dari 6, supaya row 2 dan 3 sama rata banyaknya (max 11 orang per divisi)
    function getFlexBasisAndMaxWidth(totalItems) {
        if(totalItems > 6 && window.innerWidth > 1024) { // Berlaku di large-desktop aja
            const itemsPerRow = Math.floor(totalItems / 2);
            const percentage = 100 / itemsPerRow;
            return {
                basis: `${percentage-3}%`,
                maxWidth: `calc(${percentage}% - 1rem)` // Adjust for gap
            };
        }
        return {
            basis: 'auto',
            maxWidth: 'none'
        };
    }

    function scrollToSection(structure) {
        let target = '';
        if (structure === 'Executive Board') {
            target = document.getElementById('ec');
        } else if (structure === 'LRD') {
            target = document.getElementById('lrd');
        } else if (structure === 'MIC') {
            target = document.getElementById('mic');
        }
    
        if (target) {
            const rect = target.getBoundingClientRect();
            window.scrollTo({
                top: rect.top + window.scrollY - 200,
                behavior: 'smooth'
            });
        } else {
            console.log('Target not found for structure:', structure);
        }
    }

    return(
        <div className="flex flex-col items-center gap-20 mt-20 mb-28">

            <div data-aos="fade-up" className="flex text-white font-bold gap-8">
                {structureList.map((structure, index) => (
                    <p
                    key={index}
                    className={`transition-all cursor-pointer select-none text-lg large-desktop:text-3xl hover:scale-105`}
                    onClick={() => scrollToSection(structure)}>
                        {structure}
                    </p>
                ))}
            </div>

            {/* DPI */}
            <div className="relative flex flex-col items-center gap-8">
                <p id="ec" data-aos="fade-up" className="text-white text-3xl text-center mx-60 large-desktop:text-6xl ultra-large-desktop:text-8xl font-bold">Executive Board</p>
                <p data-aos="fade-right" className="hidden tablet:block absolute top-10 w-1/2 -left-2 text-3xl large-desktop:text-9xl font-bold text-white/15 select-none">Executive Board</p>
                {/* Koordinator */}
                <div data-aos="fade-up" className="w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                    <img className="w-40 h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${dpi[0].name}.jpg`} alt="" />
                    <p className="font-bold text-center font-sans">{dpi[0].name}</p>
                    <p className="font-sans text-center">{dpi[0].position}</p>
                </div>
                {/* Staff */}
                <div className={`grid grid-cols-2 justify-items-center large-desktop:flex ${dpi.length > 6 ? 'large-desktop:flex-wrap' : ''} ${dpi.length < 3 ? 'large-desktop:justify-between' : 'large-desktop:justify-center'} large-desktop:mx-64 gap-4`}>
                    {dpi.map((i, index) => {
                        const { basis, maxWidth } = getFlexBasisAndMaxWidth(dpi.length);
                        return(
                        <div data-aos="fade-up" key={index} style={{ flexBasis: basis, maxWidth: maxWidth }} className={`flex flex-col items-center ${index === 0 ? `hidden` : ''} ${(dpi.length % 2 === 0 && index + 1 === dpi.length) ? 'col-span-2' : ''}`}>
                            <div className="w-36 large-desktop:w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                                <img className="w-28 h-28 large-desktop:w-40 large-desktop:h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${i.name}.jpg`} alt="" />
                                <p className="font-bold text-center font-sans">{i.name}</p>
                                <p className="font-sans text-center">{i.position}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                {/* Penjelasan */}
                <div data-aos="fade-up" className="flex flex-col large-desktop:flex-row justify-center items-center mx-40">
                    <div className='order-first large-desktop:order-none w-2/3 large-desktop:w-1/2 max-w-fit p-4 bg-silver rounded-2xl'><img className="rounded-xl" src={process.env.PUBLIC_URL + '/images/group-photo/DPI.jpg'} alt="" /></div>
                    <p className="text-white text-justify large-desktop:w-1/2 px-10 py-6 large-desktop:text-xl ultra-large-desktop:text-3xl font-sans"><b>Executive Board</b> atau Dewan Pengurus Inti (DPI) merupakan inti dari kepemimpinan Himpunan Mahasiswa Statistika BINUS University. DPI terdiri atas president, secretary, dan treasurer. Dewan Pengurus Inti (DPI) bertanggung jawab untuk mengarahkan dan mengawasi jalannya organisasi, mengambil keputusan strategis, serta bertindak sebagai perwakilan resmi organisasi baik di dalam maupun di luar universitas. DPI juga memiliki hak dan wewenang untuk menetapkan peraturan, tata tertib, dan kebijakan yang diperlukan untuk menjalankan tugas dan kewajiban organisasi.</p>
                </div>
            </div>

            {/* LRD */}
            <div className="relative flex flex-col items-center gap-8">
                <p id="lrd" data-aos="fade-up" className="text-white text-3xl text-center mx-60 large-desktop:text-6xl ultra-large-desktop:text-8xl font-bold">Learning, Resource, and Development</p>
                <p data-aos="fade-right" className="hidden tablet:block absolute top-10 w-1/2 -left-2 text-3xl large-desktop:text-7xl large-desktop:text-8xl font-bold text-white/15 select-none">Learning, Resource, and Development</p>
                {/* Koordinator */}
                <div className="w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                    <img data-aos="fade-up" className="w-40 h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${lrd[0].name}.jpg`} alt="" />
                    <p className="font-bold text-center font-sans">{lrd[0].name}</p>
                    <p className="font-sans text-center">{lrd[0].position}</p>
                </div>
                {/* Staff */}
                <div className={`grid grid-cols-2 justify-items-center large-desktop:flex ${lrd.length > 6 ? 'large-desktop:flex-wrap' : ''} ${lrd.length < 3 ? 'large-desktop:justify-between' : 'large-desktop:justify-center'} large-desktop:mx-64 gap-4`}>
                    {lrd.map((i, index) => {
                        const { basis, maxWidth } = getFlexBasisAndMaxWidth(lrd.length);
                        return(
                        <div data-aos="fade-up" key={index} style={{ flexBasis: basis, maxWidth: maxWidth }} className={`flex flex-col items-center ${index === 0 ? `hidden` : ''} ${(lrd.length % 2 === 0 && index + 1 === lrd.length) ? 'col-span-2' : ''}`}>
                            <div className="w-36 large-desktop:w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                                <img className="w-28 h-28 large-desktop:w-40 large-desktop:h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${i.name}.jpg`} alt="" />
                                <p className="font-bold text-center font-sans">{i.name}</p>
                                <p className="font-sans text-center">{i.position}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                {/* Penjelasan */}
                <div className="flex flex-col large-desktop:flex-row justify-center items-center mx-40">
                    <p data-aos="fade-up" className="text-white text-justify px-10 py-6 large-desktop:text-xl ultra-large-desktop:text-3xl font-sans"><b>Learning, Resource, and Development</b> (LRD) bertanggung jawab atas pengembangan pembelajaran, sumber daya, dan pengembangan Himpunan Mahasiswa Statistika (HIMSTAT) BINUS University. Divisi Learning, Resource, and Development (LRD) terdiri atas satu director yang menaungi dua subdivisi, yaitu Human Resource Development and Counseling (HRDC) dan Education and Technology (ET).</p>
                </div>
            </div>

            {/* HRDC */}
            <div className="relative flex flex-col items-center gap-8">
                <p data-aos="fade-up" className="text-white text-2xl text-center mx-60 large-desktop:text-5xl ultra-large-desktop:text-8xl font-bold">Human Resource Development and Counseling</p>
                <p data-aos="fade-right" className="hidden tablet:block absolute top-10 w-1/2 -left-2 text-3xl large-desktop:text-7xl font-bold text-white/15 select-none">Human Resource Development and Counseling</p>
                {/* Koordinator */}
                <div data-aos="fade-up" className="w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                    <img className="w-40 h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${hrdc[0].name}.jpg`} alt="" />
                    <p className="font-bold text-center font-sans">{hrdc[0].name}</p>
                    <p className="font-sans text-center">{hrdc[0].position}</p>
                </div>
                {/* Staff */}
                <div className={`grid grid-cols-2 justify-items-center large-desktop:flex ${hrdc.length > 6 ? 'large-desktop:flex-wrap' : ''} ${hrdc.length < 3 ? 'large-desktop:justify-between' : 'large-desktop:justify-center'} large-desktop:mx-64 gap-4`}>
                    {hrdc.map((i, index) => {
                        const { basis, maxWidth } = getFlexBasisAndMaxWidth(hrdc.length);
                        return(
                        <div data-aos="fade-up" key={index} style={{ flexBasis: basis, maxWidth: maxWidth }} className={`flex flex-col items-center ${index === 0 ? `hidden` : ''} ${(hrdc.length % 2 === 0 && index + 1 === hrdc.length) ? 'col-span-2' : ''}`}>
                            <div className="w-36 large-desktop:w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                                <img className="w-28 h-28 large-desktop:w-40 large-desktop:h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${i.name}.jpg`} alt="" />
                                <p className="font-bold text-center font-sans">{i.name}</p>
                                <p className="font-sans text-center">{i.position}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                {/* Penjelasan */}
                <div data-aos="fade-up" className="flex flex-col large-desktop:flex-row justify-center items-center mx-40">
                    <p className="text-white text-justify large-desktop:w-1/2 px-10 py-6 large-desktop:text-xl ultra-large-desktop:text-3xl font-sans"><b>Human Resource Development and Counseling</b> bertanggung jawab atas pengelolaan sumber daya manusia dalam organisasi. HRDC mengurus perekrutan, pelatihan, pengembangan keterampilan, dan pengelolaan kinerja anggota. HRDC memastikan bahwa setiap anggota mendapatkan dukungan yang dibutuhkan untuk berkembang dan berkontribusi secara maksimal. Selain itu, HRDC menyediakan layanan konseling dan dukungan bagi anggota yang memerlukan bantuan terkait akademik, personal, atau masalah lainnya. HRDC berperan dalam menjaga kesejahteraan anggota dan menciptakan lingkungan yang mendukung.</p>
                    <div className='order-first large-desktop:order-none w-2/3 large-desktop:w-1/2 max-w-fit p-4 bg-silver rounded-2xl'><img className="rounded-xl" src={process.env.PUBLIC_URL + '/images/group-photo/HRDC.jpg'} alt="" /></div>
                </div>
            </div>

            {/* ET */}
            <div className="relative flex flex-col items-center gap-8">
                <p data-aos="fade-up" className="text-white text-3xl text-center mx-60 large-desktop:text-6xl ultra-large-desktop:text-8xl font-bold">Education and Technology</p>
                <p data-aos="fade-right" className="hidden tablet:block absolute top-10 w-1/2 -left-2 text-3xl large-desktop:text-7xl font-bold text-white/15 select-none">Education and Technology</p>
                {/* Koordinator */}
                <div data-aos="fade-up" className="w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                    <img className="w-40 h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${et[0].name}.jpg`} alt="" />
                    <p className="font-bold text-center font-sans">{et[0].name}</p>
                    <p className="font-sans text-center">{et[0].position}</p>
                </div>
                {/* Staff */}
                <div className={`grid grid-cols-2 justify-items-center large-desktop:flex ${et.length > 6 ? 'large-desktop:flex-wrap' : ''} ${et.length < 3 ? 'large-desktop:justify-between' : 'large-desktop:justify-center'} large-desktop:mx-64 gap-4`}>
                    {et.map((i, index) => {
                        const { basis, maxWidth } = getFlexBasisAndMaxWidth(et.length);
                        return(
                        <div data-aos="fade-up" key={index} style={{ flexBasis: basis, maxWidth: maxWidth }} className={`flex flex-col items-center ${index === 0 ? `hidden` : ''} ${(et.length % 2 === 0 && index + 1 === et.length) ? 'col-span-2' : ''}`}>
                            <div className="w-36 large-desktop:w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                                <img className="w-28 h-28 large-desktop:w-40 large-desktop:h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${i.name}.jpg`} alt="" />
                                <p className="font-bold text-center font-sans">{i.name}</p>
                                <p className="font-sans text-center">{i.position}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                {/* Penjelasan */}
                <div data-aos="fade-up" className="flex flex-col large-desktop:flex-row justify-center items-center mx-40">
                    <div className='order-first large-desktop:order-none w-2/3 large-desktop:w-1/2 max-w-fit p-4 bg-silver rounded-2xl'><img className="rounded-xl" src={process.env.PUBLIC_URL + '/images/group-photo/ET.jpg'} alt="" /></div>
                    <p className="text-white text-justify large-desktop:w-1/2 px-10 py-6 large-desktop:text-xl ultra-large-desktop:text-3xl font-sans"><b>Education and Technology</b> mengatur dan mengelola kegiatan pendidikan seperti seminar, workshop, dan kursus tambahan yang bertujuan untuk meningkatkan pengetahuan dan keterampilan anggota dalam bidang statistika dan teknologi. ET juga bertanggung jawab atas pengembangan dan implementasi teknologi yang mendukung kegiatan organisasi, termasuk sistem manajemen informasi dan alat bantu pembelajaran berbasis teknologi.</p>
                </div>
            </div>

            {/* MIC */}
            <div className="relative flex flex-col items-center gap-8">
                <p id="mic" data-aos="fade-up" className="text-white text-2xl text-center mx-60 large-desktop:text-5xl ultra-large-desktop:text-8xl font-bold">Media, Information, and Communication</p>
                <p data-aos="fade-right" className="hidden tablet:block absolute top-10 w-1/2 -left-2 text-3xl large-desktop:text-7xl font-bold text-white/15 select-none">Media, Information, and Communication</p>
                {/* Koordinator */}
                <div data-aos="fade-up" className="w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                    <img className="w-40 h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${mic[0].name}.jpg`} alt="" />
                    <p className="font-bold text-center font-sans">{mic[0].name}</p>
                    <p className="font-sans text-center">{mic[0].position}</p>
                </div>
                {/* Staff */}
                <div className={`grid grid-cols-2 justify-items-center large-desktop:flex ${mic.length > 6 ? 'large-desktop:flex-wrap' : ''} ${mic.length < 3 ? 'large-desktop:justify-between' : 'large-desktop:justify-center'} large-desktop:mx-64 gap-4`}>
                    {mic.map((i, index) => {
                        const { basis, maxWidth } = getFlexBasisAndMaxWidth(mic.length);
                        return(
                        <div data-aos="fade-up" key={index} style={{ flexBasis: basis, maxWidth: maxWidth }} className={`flex flex-col items-center ${index === 0 ? `hidden` : ''} ${(mic.length % 2 === 0 && index + 1 === mic.length) ? 'col-span-2' : ''}`}>
                            <div className="w-36 large-desktop:w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                                <img className="w-28 h-28 large-desktop:w-40 large-desktop:h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${i.name}.jpg`} alt="" />
                                <p className="font-bold text-center font-sans">{i.name}</p>
                                <p className="font-sans text-center">{i.position}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                {/* Penjelasan */}
                <div data-aos="fade-up" className="flex flex-col large-desktop:flex-row justify-center items-center mx-40">
                    <p className="text-white text-justify px-10 py-6 large-desktop:text-xl ultra-large-desktop:text-3xl font-sans"><b>Media, Information, and Communication</b> bertanggung jawab atas manajemen media, informasi, dan komunikasi Himpunan Mahasiswa Statistika (HIMSTAT) BINUS University. Divisi Media, Information, and Communication (MIC) terdiri atas satu director yang menaungi tiga subdivisi, yaitu Visual Communication Design (VCD), Public Relations (PR), dan Web Development (Web Dev).</p>
                </div>
            </div>

            {/* VCD */}
            <div className="relative flex flex-col items-center gap-8">
                <p data-aos="fade-up" className="text-white text-3xl text-center mx-60 large-desktop:text-6xl ultra-large-desktop:text-8xl font-bold">Visual Communication Design</p>
                <p data-aos="fade-right" className="hidden tablet:block absolute top-10 w-1/2 -left-2 text-3xl large-desktop:text-7xl font-bold text-white/15 select-none">Visual Communication Design</p>
                {/* Koordinator */}
                <div data-aos="fade-up" className="w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                    <img className="w-40 h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${vcd[0].name}.jpg`} alt="" />
                    <p className="font-bold text-center font-sans">{vcd[0].name}</p>
                    <p className="font-sans text-center">{vcd[0].position}</p>
                </div>
                {/* Staff */}
                <div className={`grid grid-cols-2 justify-items-center large-desktop:flex ${vcd.length > 6 ? 'large-desktop:flex-wrap' : ''} ${vcd.length < 3 ? 'large-desktop:justify-between' : 'large-desktop:justify-center'} large-desktop:mx-64 gap-4`}>
                    {vcd.map((i, index) => {
                        const { basis, maxWidth } = getFlexBasisAndMaxWidth(vcd.length);
                        return(
                        <div data-aos="fade-up" key={index} style={{ flexBasis: basis, maxWidth: maxWidth }} className={`flex flex-col items-center ${index === 0 ? `hidden` : ''} ${(vcd.length % 2 === 0 && index + 1 === vcd.length) ? 'col-span-2' : ''}`}>
                            <div className="w-36 large-desktop:w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                                <img className="w-28 h-28 large-desktop:w-40 large-desktop:h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${i.name}.jpg`} alt="" />
                                <p className="font-bold text-center font-sans">{i.name}</p>
                                <p className="font-sans text-center">{i.position}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                {/* Penjelasan */}
                <div data-aos="fade-up" className="flex flex-col large-desktop:flex-row justify-center items-center mx-40">
                    <p className="text-white text-justify large-desktop:w-1/2 px-10 py-6 large-desktop:text-xl ultra-large-desktop:text-3xl font-sans"><b>Visual Communication Design</b> mengelola desain komunikasi visual organisasi. VCD bertugas membuat desain grafis untuk poster, spanduk, media sosial, dan publikasi lainnya. VCD memastikan bahwa semua materi komunikasi visual menarik, konsisten, dan efektif dalam menyampaikan pesan organisasi.</p>
                    <div className='order-first large-desktop:order-none w-2/3 large-desktop:w-1/2 max-w-fit p-4 bg-silver rounded-2xl'><img className="rounded-xl" src={process.env.PUBLIC_URL + '/images/group-photo/VCD.jpg'} alt="" /></div>
                </div>
            </div>

            {/* PR */}
            <div className="relative flex flex-col items-center gap-8">
                <p data-aos="fade-up" className="text-white text-3xl text-center mx-60 large-desktop:text-7xl ultra-large-desktop:text-8xl font-bold">Public Relations</p>
                <p data-aos="fade-right" className="hidden tablet:block absolute top-10 w-1/2 -left-2 text-3xl large-desktop:text-7xl large-desktop:text-8xl font-bold text-white/15 select-none">Public Relations</p>
                {/* Koordinator */}
                <div data-aos="fade-up" className="w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                    <img className="w-40 h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${pr[0].name}.jpg`} alt="" />
                    <p className="font-bold text-center font-sans">{pr[0].name}</p>
                    <p className="font-sans text-center">{pr[0].position}</p>
                </div>
                {/* Staff */}
                <div className={`grid grid-cols-2 justify-items-center large-desktop:flex ${pr.length > 6 ? 'large-desktop:flex-wrap' : ''} ${pr.length < 3 ? 'large-desktop:justify-between' : 'large-desktop:justify-center'} large-desktop:mx-64 gap-4`}>
                    {pr.map((i, index) => {
                        const { basis, maxWidth } = getFlexBasisAndMaxWidth(pr.length);
                        return(
                        <div data-aos="fade-up" key={index} style={{ flexBasis: basis, maxWidth: maxWidth }} className={`flex flex-col items-center ${index === 0 ? `hidden` : ''} ${(pr.length % 2 === 0 && index + 1 === pr.length) ? 'col-span-2' : ''}`}>
                            <div className="w-36 large-desktop:w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                                <img className="w-28 h-28 large-desktop:w-40 large-desktop:h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${i.name}.jpg`} alt="" />
                                <p className="font-bold text-center font-sans">{i.name}</p>
                                <p className="font-sans text-center">{i.position}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                {/* Penjelasan */}
                <div data-aos="fade-up" className="flex flex-col large-desktop:flex-row justify-center items-center mx-40">
                    <div className='order-first large-desktop:order-none w-2/3 large-desktop:w-1/2 max-w-fit p-4 bg-silver rounded-2xl'><img className="rounded-xl" src={process.env.PUBLIC_URL + '/images/group-photo/PR.jpg'} alt="" /></div>
                    <p className="text-white text-justify large-desktop:w-1/2 px-10 py-6 large-desktop:text-xl ultra-large-desktop:text-3xl font-sans"><b>Public Relations</b> mengelola hubungan publik dan komunikasi eksternal organisasi. PR bertanggung jawab untuk membangun dan memelihara citra positif organisasi di mata publik, media, dan pemangku kepentingan lainnya. Ini termasuk penanganan media, rilis pers, kolaborasi dengan organisasi lain, dan manajemen reputasi.</p>
                </div>
            </div>

            {/* WEBDEV */}
            <div className="relative flex flex-col items-center gap-8">
                <p data-aos="fade-up" className="text-white text-3xl text-center mx-60 large-desktop:text-7xl ultra-large-desktop:text-8xl font-bold">Website Development</p>
                <p data-aos="fade-right" className="hidden tablet:block absolute top-10 w-1/2 -left-2 text-3xl large-desktop:text-7xl large-desktop:text-8xl font-bold text-white/15 select-none">Website Development</p>
                {/* Koordinator */}
                <div data-aos="fade-up" className="w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                    <img className="w-40 h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${web[0].name}.jpg`} alt="" />
                    <p className="font-bold text-center font-sans">{web[0].name}</p>
                    <p className="font-sans text-center">{web[0].position}</p>
                </div>
                {/* Staff */}
                <div className={`grid grid-cols-2 justify-items-center large-desktop:flex ${web.length > 6 ? 'large-desktop:flex-wrap' : ''} ${web.length < 3 ? 'large-desktop:justify-between' : 'large-desktop:justify-center'} large-desktop:mx-64 gap-4`}>
                    {web.map((i, index) => {
                        const { basis, maxWidth } = getFlexBasisAndMaxWidth(web.length);
                        return(
                        <div data-aos="fade-up" key={index} style={{ flexBasis: basis, maxWidth: maxWidth }} className={`flex flex-col items-center ${index === 0 ? `hidden` : ''} ${(web.length % 2 === 0 && index + 1 === web.length) ? 'col-span-2' : ''}`}>
                            <div className="w-36 large-desktop:w-48 h-full p-4 bg-silver flex flex-col items-center rounded-xl">
                                <img className="w-28 h-28 large-desktop:w-40 large-desktop:h-40 object-cover rounded-xl" src={`${process.env.PUBLIC_URL}/images/self-photo/${i.name}.jpg`} alt="" />
                                <p className="font-bold text-center font-sans">{i.name}</p>
                                <p className="font-sans text-center">{i.position}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                {/* Penjelasan */}
                <div data-aos="fade-up" className="flex flex-col large-desktop:flex-row justify-center items-center mx-40">
                    <p className="text-white text-justify large-desktop:w-1/2 px-10 py-6 large-desktop:text-xl ultra-large-desktop:text-3xl font-sans"><b>Website Development</b> bertanggung jawab atas pengembangan dan pemeliharaan situs web organisasi. Tugas Web Dev mencakup desain, coding, pembaruan konten, dan pemecahan masalah teknis. Web Dev memastikan bahwa situs web himpunan selalu up-to-date dan berfungsi dengan baik untuk memberikan informasi dan layanan kepada anggota dan pengunjung.</p>
                    <div className='order-first large-desktop:order-none w-2/3 large-desktop:w-1/2 max-w-fit p-4 bg-silver rounded-2xl'><img className="rounded-xl" src={process.env.PUBLIC_URL + '/images/group-photo/WEB.jpg'} alt="" /></div>
                </div>
            </div>
        </div>
    )
}