import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
import Circle from "../Components/Circle";
import { dpi, lrd, hrdc, et, mic, vcd, pr, web } from '../Components/DataOfStructure';

export default function About() {

    useEffect(() => {
        document.title = "About - HIMSTAT";
        Aos.init();
    }, []);
    
    const [activeInfo, setActiveInfo] = useState(0);
    const [activeStructure, setActiveStructure] = useState(1);

    const infoList = [
        'Profile',
        'Vision & Mission',
        'Logo',
        'Structure'
    ]

    const structureList = [
        'LRD',
        'Executive Board',
        'MIC'
    ]

    return(
        <div className="flex flex-col items-center my-24">
            <Circle y="-translate-y-[50%]" x="-translate-x-1/2"></Circle>

            <div className="flex text-white text-center font-sans border-b-2 border-white select-none cursor-pointer text-xs font-semibold desktop:text-xl ultra-large-desktop:text-2xl">
                {infoList.map((info, index) => (
                    <div className={`flex items-center transition-all justify-center w-20 desktop:w-44 px-0.5 py-1 desktop:py-2 ${activeInfo === index ? 'text-midnight bg-white' : 'hover:text-midnight hover:bg-white'}`} onClick={() => {setActiveInfo(index)}}>
                        <h3 key={index}>{info}</h3>
                    </div>
                ))}
            </div>

            <div className={`my-16`}>

                {/* OUR PROFILE */}
                {activeInfo === 0 ?
                    <div className={`flex flex-col items-center gap-16 mx-16 desktop:mx-72 ultra-large-desktop:mx-96 text-white overflow-hidden`}>
                        <div className="flex flex-col gap-10 items-center">
                            <h1 data-aos="fade-up" className="text-3xl desktop:text-6xl font-bold">OUR PROFILE</h1>
                            <iframe className='desktop:min-w-[50vw] aspect-video rounded-lg' src="https://www.youtube.com/embed/GAb3raS8beg" title="Himpunan Mahasiswa Statistika (HIMSTAT) 2022 - 2023 - Student Association" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"></iframe>
                            <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">Himpunan Mahasiswa Statistika BINUS University atau yang disingkat menjadi <b>HIMSTAT BINUS University</b> merupakan Organisasi Kemahasiswaan di Universitas Bina Nusantara berbentuk Himpunan Mahasiswa Jurusan (HMJ) yang didirikan pada tanggal 11 September 2000. HIMSTAT BINUS University adalah organisasi yang bersifat kemahasiswaan, keilmuan, kekeluargaan, non-politik, dan dinamis.</p>
                            <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">Tujuan dibentuknya HIMSTAT BINUS University adalah sebagai <b>wadah</b> bagi para mahasiswa jurusan ganda <b>Teknik Informatika dan Statistika</b> BINUS University untuk <b>belajar dan melatih soft skill</b> dengan cara berorganisasi, meningkatkan prestasi akademik mahasiswa jurusan ganda Teknik Informatika dan Statistika BINUS University, serta meningkatkan <b>minat dan apresiasi</b> seluruh mahasiswa BINUS University serta masyarakat umum terhadap ilmu Statistika.</p>
                        </div>
                        <div className="flex flex-col gap-10">
                            <h1 data-aos="fade-up" className="text-3xl desktop:text-6xl font-bold text-center">OUR HISTORY</h1>
                            <div className="flex flex-col gap-4">
                                <h4 data-aos="fade-up" className="font-bold text-xl desktop:text-2xl text-justify font-sans text-left w-full">Awal Berdiri</h4>
                                <img data-aos="fade-up" className="bg-silver w-1/3 desktop:w-1/4 p-1 rounded-lg" src={process.env.PUBLIC_URL + 'images/logo/logo-himstat-old-1.png'} alt="Logo HIMSTAT Pertama" />
                                <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">HIMSTAT (Himpunan Mahasiswa Statistika) resmi berdiri pada tanggal 11 September 2000 sebagai organisasi mahasiswa yang ditujukan kepada jurusan double program Computer Science and Statistics di BINUS University. Pada awal berdirinya, HIMSTAT terdiri dari 60 anggota yang memiliki semangat untuk memajukan program studi teknik informatika dan statistika, serta meningkatkan keterampilan dan prestasi mahasiswa.</p>
                                <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">HIMSTAT didirikan dengan tujuan utama untuk menjadi wadah yang menampung aspirasi mahasiswa jurusan Computer Science dan Statistics. Selain itu, HIMSTAT berkomitmen untuk mengembangkan dan melaksanakan berbagai program yang bertujuan meningkatkan prestasi akademik, keterampilan teknis, serta kemampuan interpersonal para anggotanya. Melalui kegiatan-kegiatan yang inovatif dan berorientasi pada pengembangan diri, HIMSTAT berupaya menciptakan lingkungan belajar yang mendukung dan inspiratif bagi seluruh anggotanya. HIMSTAT juga mendukung pelaksanaan Tri Dharma Perguruan Tinggi, yaitu pendidikan, penelitian, dan pengabdian kepada masyarakat, dengan menyelenggarakan program-program yang mendukung ketiga aspek tersebut.</p>
                            </div>
                            <div className="flex flex-col gap-4">
                                <h4 data-aos="fade-up" className="font-bold desktop:text-2xl text-justify font-sans">Perubahan Struktur Kepengurusan</h4>
                                <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">Selama perjalanannya, HIMSTAT telah mengalami beberapa kali perubahan struktur kepengurusan guna menyesuaikan diri dengan dinamika dan kebutuhan organisasi. Pada 2011, HIMSTAT memiliki dua divisi utama yaitu:</p>
                                <ol data-aos="fade-up" className="list-decimal pl-6">
                                    <li data-aos="fade-up"><p data-aos="fade-up" className="font-bold text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">Divisi Public Relation and Academic Quality Control</p></li>
                                    <li data-aos="fade-up"><p data-aos="fade-up" className="font-bold text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">Divisi Design and Technology</p></li>
                                </ol>
                                <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">Seiring dengan perkembangan zaman dan kebutuhan organisasi yang semakin kompleks, struktur kepengurusan HIMSTAT saat ini telah berkembang menjadi lebih terstruktur dan spesifik. Saat ini, HIMSTAT memiliki dua divisi utama, yaitu:</p>
                                <ol data-aos="fade-up" className="list-decimal pl-6">
                                    <li data-aos="fade-up">
                                        <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans"><b>Learning, Resource, and Development (LRD)</b> yang terdiri dari dua subdivisi:</p>
                                        <ol className="list-disc pl-6">
                                            <li><p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans"><b>Human Resource Development and Counseling (HRDC):</b> Bertanggung jawab atas pengembangan sumber daya manusia dan konseling.</p></li>
                                            <li><p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans"><b>Education and Technology (ET):</b> Berfokus pada pengembangan pendidikan dan teknologi.</p></li>
                                        </ol>
                                    </li>
                                    <br />
                                    <li data-aos="fade-up">
                                        <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans"><b>Media, Information, and Communication (MIC)</b> yang terdiri dari tiga subdivisi:</p>
                                        <ol className="list-disc pl-6">
                                            <li data-aos="fade-up"><p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans"><b>Visual Communication Design (VCD):</b> Mengelola desain komunikasi visual.</p></li>
                                            <li data-aos="fade-up"><p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans"><b>Public Relations (PR):</b> Bertanggung jawab atas hubungan masyarakat dan komunikasi eksternal.</p></li>
                                            <li data-aos="fade-up"><p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans"><b>Web Development:</b> Mengembangkan dan memelihara website organisasi.</p></li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <div className="flex flex-col gap-4">
                                <h4 data-aos="fade-up" className="font-bold desktop:text-2xl text-justify font-sans text-left">Perubahan Logo</h4>
                                <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">Selama lebih dari dua dekade eksistensinya, HIMSTAT telah mengalami perubahan logo sebanyak tiga kali. Perubahan ini mencerminkan evolusi dan dinamika organisasi yang terus beradaptasi dengan perkembangan zaman serta kebutuhan anggotanya.</p>
                                <img src={process.env.PUBLIC_URL + '/images/logo/logo-modification.png'} alt="" />
                            </div>
                            <p data-aos="fade-up" className="text-xs desktop:text-xl ultra-large-desktop:text-2xl text-justify font-sans">Melalui berbagai perubahan dan perkembangan yang telah terjadi, HIMSTAT tetap berkomitmen untuk menjadi organisasi yang dapat memberikan kontribusi positif bagi mahasiswa jurusan Computer Science dan Statistics di BINUS University. Kami bangga dengan sejarah kami dan terus berupaya untuk membangun masa depan yang lebih cerah bagi seluruh anggota HIMSTAT.</p>
                        </div>
                    </div>

                // VISION AND MISSION
                : activeInfo === 1 ?
                    <div className={`flex flex-col gap-10 items-center mx-16 desktop:mx-72 ultra-large-desktop:mx-96`}>
                        <h1 className="text-white text-3xl desktop:text-6xl font-bold">VISION</h1>
                        <p className="text-xs desktop:text-xl ultra-large-desktop:text-2xl font-sans text-white text-justify font-sans">Menjadikan Himpunan Mahasiswa Statistika BINUS University sebagai organisasi kemahasiswaan yang mewadahi pengembangan ilmu pengetahuan, keterampilan, dan kekeluargaan, serta aktif dalam pengabdian kepada masyarakat bagi mahasiswa program studi ganda Teknik Informatika dan Statistika untuk menjadi organisasi yang dikenal unggul dalam skala nasional pada tahun 2027.</p>
                        <h1 className="text-white text-3xl desktop:text-6xl font-bold">MISSION</h1>
                        <ol className="text-xs desktop:text-xl ultra-large-desktop:text-2xl font-sans text-white text-justify font-sans list-decimal">
                            <li><p>Melaksanakan kegiatan pengajaran, pelatihan, serta kompetisi bagi seluruh mahasiswa program studi ganda Teknik Informatika dan Statistika BINUS University untuk mengembangkan kemampuan akademik dan nonakademik.</p></li>
                            <li><p>Berkontribusi melalui kegiatan pelayanan dan pengabdian kepada masyarakat dalam bidang keilmuan teknik informatika dan statistika.</p></li>
                            <li><p>Membangun dan memperkuat kekeluargaan antaranggota melalui kegiatan dan program kerja yang mengedepankan kolaborasi, kebersamaan, dan kerja sama.</p></li>
                            <li><p>Membina hubungan dan kerja sama dengan pihak eksternal, seperti organisasi kemahasiswaan, mitra sponsor, dan asosiasi keilmuan statistika di Indonesia.</p></li>
                        </ol>
                    </div>

                // LOGO
                : activeInfo === 2 ?
                    <div className={`flex flex-col justify-center items-center mx-16 desktop:mx-36 ultra-large-desktop:mx-72 gap-10`}>
                        <h1 className="text-white text-2xl desktop:text-6xl font-bold">HIMSTAT'S LOGO</h1>
                        <div className="flex flex-col desktop:flex-row gap-14 justify-center items-center">
                            <div className="flex justify-center bg-silver rounded-2xl w-1/2"><img src={process.env.PUBLIC_URL + 'images/logo/logo-himstat.png'} alt="" className="p-2 desktop:p-6"/></div>
                            <div className="flex flex-col gap-10 desktop:w-2/3">
                                <h2 data-aos="fade-up" className="text-white desktop:text-3xl ultra-large-desktop:text-4xl font-bold">Makna warna pada lambang:</h2>
                                <ol className="list-decimal text-white text-xs desktop:text-lg ultra-large-desktop:text-xl font-sans text-justify">
                                    <li data-aos="fade-up"><p><b>Warna biru</b> melambangkan ketenangan Himpunan Mahasiswa Statistika BINUS University dalam menghadapi tantangan dan rintangan selama pelaksanaan program kerja.</p></li>
                                    <li data-aos="fade-up"><p><b>Warna putih</b> melambangkan kesucian hati dalam membangun dan mengembangkan Himpunan Mahasiswa Statistika BINUS University  dengan penuh sukarela.</p></li>
                                    <li data-aos="fade-up"><p><b>Warna abu-abu</b> melambangkan keseriusan Himpunan Mahasiswa Statistika BINUS University dalam menjalankan program kerja, serta keandalan yang diakui oleh BINUS University.</p></li>
                                    <li data-aos="fade-up"><p><b>Warna merah</b> melambangkan keaktifan dan produktivitas setiap anggota dalam membangun dan mengembangkan Himpunan Mahasiswa Statistika BINUS University, serta mencerminkan keberanian dalam memperkenalkan program studi ganda Teknik Informatika dan Statistika BINUS University serta ilmu statistika kepada masyarakat luas.</p></li>
                                </ol>
                                <h2 data-aos="fade-up" className="text-white desktop:text-3xl ultra-large-desktop:text-4xl font-bold">Makna bentuk pada lambang:</h2>
                                <ol className="list-decimal text-white text-xs desktop:text-lg ultra-large-desktop:text-xl font-sans text-justify">
                                    <li data-aos="fade-up"><p><b>Bentuk lingkaran</b> melambangkan persahabatan, keabadian, kesatuan, dan stabilitas.</p></li>
                                    <li data-aos="fade-up"><p><b>Potongan juring berwarna merah</b> yang menjorok keluar dari diagram lingkaran melambangkan keberanian Himpunan Mahasiswa Statistika BINUS University untuk berkarya, tidak hanya di lingkungan BINUS University, tetapi juga di lingkungan masyarakat.</p></li>
                                </ol>
                            </div>
                        </div>
                    </div>

                // STRUCTURE
                : activeInfo === 3 ?
                    <div className={`flex flex-col gap-10 items-center mx-14 desktop:mx-72 ultra-large-desktop:mx-96 text-white`}>
                        <h1 className="text-3xl desktop:text-6xl font-bold text-center">ORGANIZATIONAL STRUCTURE</h1>
                        <img data-aos="fade-up" src={process.env.PUBLIC_URL + 'images/organizational-structure/organization-structure.jpg'} alt=""/>
                        <div className="flex flex-col gap-10 items-center">
                            <div data-aos="fade-up" className="flex font-bold gap-8">
                                {structureList.map((structure, index) => (
                                    <h2 key={index} className={` transition-all cursor-pointer select-none ${activeStructure === index ? 'opacity-100 text-lg desktop:text-3xl' : 'opacity-50 text-sm desktop:text-2xl'}`} onClick={() => setActiveStructure(index)}>{structure}</h2>
                                ))}
                            </div>

                            <div data-aos="fade-up">
                                {activeStructure === 0 ?
                                    // LRD
                                    <div className="flex flex-col gap-12 font-sans text-xs desktop:text-xl ultra-large-desktop:text-2xl">
                                        <div className="flex flex-col gap-6">
                                            <p data-aos="fade-up" className="text-justify">Learning, Resource, and Development (LRD) bertanggung jawab atas pengembangan pembelajaran, sumber daya, dan pengembangan Himpunan Mahasiswa Statistika (HIMSTAT) BINUS University. Divisi Learning, Resource, and Development (LRD) terdiri atas satu director yang menaungi dua subdivisi, yaitu Human Resource Development and Counseling (HRDC) dan Education and Technology (ET).</p>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">LRD Director:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>{lrd[0].name}</p></li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* HRDC */}
                                        <div className="flex flex-col gap-6">
                                            <h3 data-aos="fade-up" className="text-center desktop:text-3xl font-bold">Human Resource Development and Counseling</h3>
                                            <div data-aos="fade-up" className="desktop:w-1/2 rounded-2xl self-center bg-silver p-4">
                                                <img className="rounded-xl border" src={process.env.PUBLIC_URL + 'images/group-photo/HRDC.jpg'} alt="HRDC Team"/>
                                            </div>
                                            <p data-aos="fade-up" className="text-justify">Human Resource Development and Counseling bertanggung jawab atas pengelolaan sumber daya manusia dalam organisasi. HRDC mengurus perekrutan, pelatihan, pengembangan keterampilan, dan pengelolaan kinerja anggota. HRDC memastikan bahwa setiap anggota mendapatkan dukungan yang dibutuhkan untuk berkembang dan berkontribusi secara maksimal. Selain itu, HRDC menyediakan layanan konseling dan dukungan bagi anggota yang memerlukan bantuan terkait akademik, personal, atau masalah lainnya. HRDC berperan dalam menjaga kesejahteraan anggota dan menciptakan lingkungan yang mendukung.</p>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">Program Kerja:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>Gathering HIMSTAT</p></li>
                                                    <li data-aos="fade-up"><p>SIWU</p></li>
                                                    <li data-aos="fade-up"><p>LDK-A</p></li>
                                                    <li data-aos="fade-up"><p>Birthday Party</p></li>
                                                    <li data-aos="fade-up"><p>Internal Counseling</p></li>
                                                    <li data-aos="fade-up"><p>Activist Recruitment</p></li>
                                                    <li data-aos="fade-up"><p>HIMSTAT Birthday Shoutout</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">HRDC Coordinator:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>{hrdc[0].name}</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">HRDC Staffs:</h5>
                                                <ul data-aos="fade-up" className="list-disc pl-6">
                                                    {hrdc.map((i, index) => 
                                                        index !== 0 && (
                                                            <li data-aos="fade-up" key={index}><p>{i.name}</p></li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* ET */}
                                        <div className="flex flex-col gap-6">
                                            <h3 data-aos="fade-up" className="text-center desktop:text-3xl font-bold">Education and Technology</h3>
                                            <div data-aos="fade-up" className="desktop:w-1/2 rounded-2xl self-center bg-silver p-4">
                                                <img className="rounded-xl border" src={process.env.PUBLIC_URL + 'images/group-photo/ET.jpg'} alt="ET Team"/>
                                            </div>
                                            <p data-aos="fade-up" className="text-justify">Education and Technology mengatur dan mengelola kegiatan pendidikan seperti seminar, workshop, dan kursus tambahan yang bertujuan untuk meningkatkan pengetahuan dan keterampilan anggota dalam bidang statistika dan teknologi. ET juga bertanggung jawab atas pengembangan dan implementasi teknologi yang mendukung kegiatan organisasi, termasuk sistem manajemen informasi dan alat bantu pembelajaran berbasis teknologi.</p>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">Program Kerja:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>DLSS</p></li>
                                                    <li data-aos="fade-up"><p>Workshop</p></li>
                                                    <li data-aos="fade-up"><p>Notes Collection</p></li>
                                                    <li data-aos="fade-up"><p>Responsi HIMSTAT</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">ET Coordinator:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>{et[0].name}</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">ET Staffs:</h5>
                                                <ul className="list-disc pl-6">
                                                    {et.map((i, index) => 
                                                        index !== 0 && (
                                                            <li data-aos="fade-up" key={index}><p>{i.name}</p></li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                : activeStructure === 1 ?
                                    // Executive Board
                                    <div className="flex flex-col gap-6 font-sans text-xs desktop:text-xl ultra-large-desktop:text-2xl">
                                        <div data-aos="fade-up" className="desktop:w-1/2 rounded-2xl self-center bg-silver p-4">
                                            <img className="rounded-xl border" src={process.env.PUBLIC_URL + 'images/group-photo/DPI.jpg'} alt="DPI Team"/>
                                        </div>
                                        <p data-aos="fade-up" className="text-justify">Executive Board atau Dewan Pengurus Inti (DPI) merupakan inti dari kepemimpinan Himpunan Mahasiswa Statistika BINUS University. DPI terdiri atas president, secretary, dan treasurer. Dewan Pengurus Inti (DPI) bertanggung jawab untuk mengarahkan dan mengawasi jalannya organisasi, mengambil keputusan strategis, serta bertindak sebagai perwakilan resmi organisasi baik di dalam maupun di luar universitas. DPI juga memiliki hak dan wewenang untuk menetapkan peraturan, tata tertib, dan kebijakan yang diperlukan untuk menjalankan tugas dan kewajiban organisasi.</p>
                                        <div>
                                            <h5 data-aos="fade-up" className="font-bold">President:</h5>
                                            <ul className="list-disc pl-6">
                                                <li data-aos="fade-up"><p>{dpi[0].name}</p></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 data-aos="fade-up" className="font-bold">Treasurer:</h5>
                                            <ul className="list-disc pl-6">
                                                <li data-aos="fade-up"><p>{dpi[1].name}</p></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h5 data-aos="fade-up" className="font-bold">Secretary:</h5>
                                            <ul className="list-disc pl-6">
                                                <li data-aos="fade-up"><p>{dpi[2].name}</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                : activeStructure === 2 ?
                                    // MIC
                                    <div className="flex flex-col gap-12 font-sans text-xs desktop:text-xl ultra-large-desktop:text-2xl">
                                        <div className="flex flex-col gap-6">
                                            <p data-aos="fade-up" className="text-justify">Media, Information, and Communication (MIC) bertanggung jawab atas manajemen media, informasi, dan komunikasi Himpunan Mahasiswa Statistika (HIMSTAT) BINUS University. Divisi Media, Information, and Communication (MIC) terdiri atas satu director yang menaungi tiga subdivisi, yaitu Visual Communication Design (VCD), Public Relations (PR), dan Web Development (Web Dev).</p>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">MIC Director:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>{mic[0].name}</p></li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* VCD */}
                                        <div className="flex flex-col gap-6">
                                            <p data-aos="fade-up" className="text-center desktop:text-3xl font-bold">Visual Communication Design</p>
                                            <div data-aos="fade-up" className="desktop:w-1/2 rounded-2xl self-center bg-silver p-4">
                                                <img className="rounded-xl border" src={process.env.PUBLIC_URL + 'images/group-photo/VCD.jpg'} alt="VCD Team"/>
                                            </div>
                                            <p data-aos="fade-up" className="text-justify">Visual Communication Design (VCD) mengelola desain komunikasi visual organisasi. VCD bertugas membuat desain grafis untuk poster, spanduk, media sosial, dan publikasi lainnya. VCD memastikan bahwa semua materi komunikasi visual menarik, konsisten, dan efektif dalam menyampaikan pesan organisasi.</p>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">Program Kerja:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>Instagram Highlights</p></li>
                                                    <li data-aos="fade-up"><p>Instagram Infographics</p></li>
                                                    <li data-aos="fade-up"><p>TikTok Content Creations</p></li>
                                                    <li data-aos="fade-up"><p>Mascot Creation</p></li>
                                                    <li data-aos="fade-up"><p>HIMSTAT Birthday Shoutout</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">VCD Coordinator:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>{vcd[0].name}</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">VCD Staffs:</h5>
                                                <ul className="list-disc pl-6">
                                                    {vcd.map((i, index) => 
                                                        index !== 0 && (
                                                            <li data-aos="fade-up" key={index}><p>{i.name}</p></li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* PR */}
                                        <div className="flex flex-col gap-6">
                                            <p data-aos="fade-up" className="text-center desktop:text-3xl font-bold">Public Relations</p>
                                            <div data-aos="fade-up" className="desktop:w-1/2 rounded-2xl self-center bg-silver p-4">
                                                <img className="rounded-xl border" src={process.env.PUBLIC_URL + 'images/group-photo/PR.jpg'} alt="PR Team"/>
                                            </div>
                                            <p data-aos="fade-up" className="text-justify">Public Relations mengelola hubungan publik dan komunikasi eksternal organisasi. PR bertanggung jawab untuk membangun dan memelihara citra positif organisasi di mata publik, media, dan pemangku kepentingan lainnya. Ini termasuk penanganan media, rilis pers, kolaborasi dengan organisasi lain, dan manajemen reputasi.</p>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">Program Kerja:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>Press Releases</p></li>
                                                    <li data-aos="fade-up"><p>Media Partnerships</p></li>
                                                    <li data-aos="fade-up"><p>Web Articles, News, and Gallery</p></li>
                                                    <li data-aos="fade-up"><p>Instagram Infographic</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">PR Coordinator:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>{pr[0].name}</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">PR Staffs:</h5>
                                                <ul className="list-disc pl-6">
                                                    {pr.map((i, index) => 
                                                        index !== 0 && (
                                                            <li data-aos="fade-up" key={index}><p>{i.name}</p></li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* WEBDEV */}
                                        <div className="flex flex-col gap-6">
                                            <p data-aos="fade-up" className="text-center desktop:text-3xl font-bold">Website Development</p>
                                            <div data-aos="fade-up" className="desktop:w-1/2 rounded-2xl self-center bg-silver p-4">
                                                <img className="rounded-xl border" src={process.env.PUBLIC_URL + 'images/group-photo/WEB.jpg'} alt="Web Dev Team"/>
                                            </div>
                                            <p data-aos="fade-up" className="text-justify">Website Development bertanggung jawab atas pengembangan dan pemeliharaan situs web organisasi. Tugas Web Dev mencakup desain, coding, pembaruan konten, dan pemecahan masalah teknis. Web Dev memastikan bahwa situs web himpunan selalu up-to-date dan berfungsi dengan baik untuk memberikan informasi dan layanan kepada anggota dan pengunjung.</p>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">Program Kerja:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>Website Management (Development, Maintenance, Site Creation, Operations, Hosting)</p></li>
                                                    <li data-aos="fade-up"><p>Technical Support (Email Management, IT Support, Technical Services)</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">Web Dev Coordinator:</h5>
                                                <ul className="list-disc pl-6">
                                                    <li data-aos="fade-up"><p>{web[0].name}</p></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 data-aos="fade-up" className="font-bold">Web Dev Staffs:</h5>
                                                <ul className="list-disc pl-6">
                                                    {web.map((i, index) => 
                                                        index !== 0 && (
                                                            <li data-aos="fade-up" key={index}><p>{i.name}</p></li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                : ''}
                            </div>
                            <div data-aos="fade-up" className="flex justify-center mt-8">
                                <Link to="/structure" className="border-[2px] rounded-full px-6 py-2 desktop:px-10 desktop:text-xl ultra-large-desktop:text-3xl hover:bg-silver hover:text-midnight transition-all hover:scale-105">View All</Link>
                            </div>
                        </div>
                    </div>
                : ''}
            </div>
        </div>
    );
}