import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import DefaultLayout from './Layout/DefaultLayout';
import Home from './Pages/Home';
import About from './Pages/About';
import Events from './Pages/Events';
import EventDetails from './Pages/EventDetails';
import Articles from './Pages/Articles';
import ArticleDetails from './Pages/ArticleDetails';
import Programs from './Pages/Programs';
import Structure from './Pages/Structure';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <DefaultLayout/>,
      children: [
        {
          path: "/",
          element: <Home/>,
        },
        {
          path: "/home",
          element: <Home/>
        },
        {
          path: "/about",
          element: <About/>,
        },
        {
          path: "/events",
          element: <Events/>,
        },
        {
          path: "/events/:ID",
          element: <EventDetails/>
        },
        {
          path: "/articles",
          element: <Articles/>
        },
        {
          path: "/articles/:ID",
          element: <ArticleDetails/>
        },
        {
          path: "/programs",
          element: <Programs/>
        },
        {
          path: "/structure",
          element: <Structure/>
        },
        {
          path: "*", // Catch-all route for undefined paths
          element: <Navigate to="/" /> // Redirect to root
        }
      ]
    },
  ])

  return (
    <RouterProvider router={router}/>
  )
}

export default App;
