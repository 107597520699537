import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ArticleDetails() {

    const nav = useNavigate();

    useEffect(() => {
        document.title = "Articles - HIMSTAT";
    }, []);

    const {ID} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [article, setArticle] = useState([]);
    const [author, setAuthor] = useState([]);

    useEffect(() => {
        sessionStorage.removeItem('visit'); // Reset 'visit' on ID change

        if (!sessionStorage.getItem('visit')) {
            updateCounter(ID);
            sessionStorage.setItem('visit', 'x');
        }
    }, [ID]);
    
    const updateCounter = (id) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", `${process.env.REACT_APP_BASE_URL}/api/update-views/article/${id}`);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status !== 204) {
                    console.error('Failed to update views');
                }
            }
        };
        xhr.send();
    };

    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const getArticle = useCallback( async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/article/${ID}`);
            const {article} = response.data;
            if (article === null) {
                nav("/");
            }
            return article;
        } catch (error) {
            nav("/");
        } finally {
            setIsLoading(false);
        }
    }, [ID, nav]);

    useEffect(() => {
        getArticle().then((result) => {
            if (result) {
                result.Text = result.Text.replace(
                    /\/storage\/ArticleStorage\/TextImage\//g,
                    `${process.env.REACT_APP_BASE_URL}/storage/ArticleStorage/TextImage/`
                );
    
                result.Text = result.Text.replace(
                    /<ul>/g,
                    '<ul class="list-disc pl-6">'
                )
    
                result.Text = result.Text.replace(
                    /<ol>/g,
                    '<ol class="list-decimal pl-6">'
                )

                result.Text = result.Text.replace(
                    /<p>/g,
                    '<p class="break-words">'
                )

                result.Text = result.Text.replace(
                    /<a/g,
                    '<a class="text-blue decoration-blue visited:text-purple-600 visited:decoration-purple-600 underline"'
                )

                result.Text = result.Text.replace(
                    /<p\s+style="text-align:\s*center;\s*".*?>/g,
                    '<p style="text-align: center; display: flex; justify-content: center;">'
                );
    
                setArticle(result);
            }
        })
    }, [getArticle]);

    useEffect(() => {
        setAuthor(article.author);
    }, [article]);


    return(
        <div className="">
            <h1 className="text-white text-3xl desktop:text-7xl font-bold px-6 desktop:px-24">HIMSTAT ARTICLES</h1>
            {isLoading ? <div className="text-center text-white py-20"><p>Loading...</p></div> :
            <div className="flex flex-col p-8 desktop:px-80 desktop:py-10 bg-white text-midnight">
                <div className="flex flex-col gap-8">
                    <div className="flex flex-col">
                        <h2 className="desktop:text-4xl font-bold break-words">{article.Title}</h2>
                        {author && <h5 className="font-sans">oleh {author.Name}</h5>}
                    </div>
                    <h5 className="font-sans">{formatDate(article.DateCreated)}</h5>
                </div>


                <div className="flex flex-col mb-24 mt-12 gap-10 text-justify font-sans min-h-screen">
                    <img className="max-h-24 desktop:max-h-48 w-40 desktop:w-96 self-center object-cover" src={process.env.REACT_APP_BASE_URL + "/storage/ArticleStorage/Thumbnail/" + article.Thumbnail} alt="" />
                    <div className="text-sm desktop:text-base" dangerouslySetInnerHTML={{ __html: article.Text }} ></div>
                </div>


                {author && 
                    <div className="flex flex-col items-center font-sans">
                        <img className="w-20 h-20 object-cover rounded-full" src={process.env.REACT_APP_BASE_URL + '/storage/AuthorStorage/ProfilePicture/' + author.ProfilePicture} alt={author.Name} />
                        <h5>{author.Name}</h5>
                    </div>
                }
            </div>}
        </div>
    )
}