import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Outlet, useLocation } from "react-router-dom";
import Opening from "../Components/Opening";

export default function DefaultLayout() {

    const location = useLocation();
    const [scrollY, setScrollY] = useState(0);
    const [navLogo, setNavLogo] = useState(process.env.PUBLIC_URL + '/images/logo/nav-logo.png');
    const [navIsHidden, setNavIsHidden] = useState(false);
    const [activeNav, setActiveNav] = useState(0);
    const [activeMobileNav, setActiveMobileNav] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [firstMount, setFirstMount] = useState(true);

    const navList = [
        "Home",
        "About",
        "Programs",
        "Events",
        "Articles"
    ]

    // function buat catet screen width
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let lastScrollY = window.scrollY;
        let ticking = false;

        const updateNavOnScroll = () => {
            const currentScrollPos = window.scrollY;

            if (currentScrollPos > lastScrollY && currentScrollPos > 100) {
                setNavIsHidden(true);
            } else if (currentScrollPos < lastScrollY) {
                setNavIsHidden(false);
            }

            if (window.scrollY === 0) {
                setNavLogo(process.env.PUBLIC_URL + '/images/logo/nav-logo.png');
            } else {
                setNavLogo(process.env.PUBLIC_URL + '/images/logo/nav-logo-dark.png');
            }

            lastScrollY = currentScrollPos;
            setScrollY(currentScrollPos);
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateNavOnScroll);
                ticking = true;
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        // Define only the apple-touch-icon
        const favicon = {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: `${process.env.PUBLIC_URL}/images/logo/logo-icon.png`
        };
        
        // Remove any existing favicons
        const existingIcons = document.querySelectorAll('link[rel="apple-touch-icon"]');
        existingIcons.forEach(icon => document.head.removeChild(icon));
        
        // Add the new apple-touch-icon
        const link = document.createElement('link');
        link.rel = favicon.rel;
        if (favicon.sizes) {
            link.sizes = favicon.sizes;
        }
        link.href = favicon.href;
        document.head.appendChild(link);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if(firstMount){
                setFirstMount(false);
            }
        }, 3000);
    }, [firstMount]);

    useEffect(() => {
        const changeActiveNav = () => {
            if(location.pathname.includes('About') || location.pathname.includes('about') || location.pathname.includes('Structure') || location.pathname.includes('structure')) setActiveNav(1);
            else if(location.pathname.includes('Programs') || location.pathname.includes('programs')) setActiveNav(2);
            else if(location.pathname.includes('Events') || location.pathname.includes('events')) setActiveNav(3);
            else if(location.pathname.includes('Articles') || location.pathname.includes('articles')) setActiveNav(4);
            else setActiveNav(0);

            window.scrollTo({top: 0});
        }
        changeActiveNav();
    }, [location.pathname]);

    return (
        <div className={`bg-midnight ${activeMobileNav ? "overflow-y-hidden max-h-screen" : ""}`}>
            {/* First Load */}
            {firstMount && <Opening></Opening>}

            {/* Helmet ini buat taro external script, karena di sini ga ada <head> */}
            <Helmet>
                <script src="https://kit.fontawesome.com/47d9cc8422.js" crossorigin="anonymous"></script>
            </Helmet>
            
            {/* Navbar Large Desktop */}
            {screenWidth >= 1024 &&
                <nav className={`sticky min-w-full z-20 top-0 transition-all ${navIsHidden ? 'opacity-0 -translate-y-full' : 'opacity-100'} flex items-center justify-center large-desktop:justify-between px-24 py-4 select-none ${scrollY === 0 ? 'text-white' : 'text-midnight bg-silver'}`}>
                    {screenWidth >= 1440 && <Link to="/"><img src={navLogo} alt="" className="w-56"/></Link>}
                    <div className="flex flex-row gap-16 text-sm">
                        {navList.map((nav, index) => {
                            const isActive = activeNav === index;
                            const isOnTop = window.scrollY === 0;
                            const activeClassName = isActive ? (isOnTop ? 'font-bold border-b' : 'font-bold border-b border-midnight') : (isOnTop ? 'hover:border-b' : 'hover:border-b hover:border-midnight');
                            
                            return(
                                <Link key={index} to={nav} className={`ultra-large-desktop:text-2xl ${activeClassName}`}>{nav}</Link>
                            )
                        })}
                        {screenWidth >= 1440 &&
                            <p className="ultra-large-desktop:text-2xl cursor-pointer" onClick={() => {window.scrollTo({top: document.body.scrollHeight, behavior:"smooth"})}}>Contact</p>
                        }
                    </div>
                </nav>
            }
        
            {/* Navbar Mobile - Desktop */}
            {screenWidth < 1024 &&
                <nav className={`sticky z-20 top-0 transition-all ${navIsHidden ? 'opacity-0 -translate-y-full' : 'opacity-100'} flex items-center justify-between px-5 py-3 select-none ${scrollY === 0 ? 'text-white' : 'text-midnight bg-silver'}`}>
                    <Link to="/" className="w-2/5"><img src={navLogo} alt=""/></Link>
                    <p className="text-3xl" onClick={() => {setActiveMobileNav(true)}}><i className="fa-solid fa-bars white"></i></p>
                </nav>
            }

            <div className={`${activeMobileNav ? "" : "translate-x-full"} fixed transition-all top-0 z-50 bg-silver w-full h-screen flex flex-col gap-8 items-center py-4`}>
                <p className="absolute top-7 right-5 text-4xl" onClick={() => {setActiveMobileNav(false)}}><i className="fa-solid fa-xmark midnight"></i></p>
                <img src={process.env.PUBLIC_URL + '/images/logo/nav-logo-dark.png'} alt="" className="w-1/2"/>
                {navList.map((nav, index) => {
                    const isActive = activeNav === index;
                    const activeClassName = isActive ? 'font-bold' : '';
                    
                    return(
                        <Link key={index} to={nav} className={activeClassName} onClick={() => setActiveMobileNav(false)}>{nav}</Link>
                    )
                })}
            </div>
            
            <div className="z-10 overflow-x-hidden min-h-screen">
                <Outlet/>
            </div>

            <footer className={`z-10 text-midnight bg-silver p-6 desktop:py-8 desktop:px-24`}>
                <div className="flex flex-col gap-2 desktop:flex-row desktop:justify-between">
                    <p className="select-none text-5xl font-bold desktop:text-8xl">CONTACT US</p>
                    <div className="text-sm desktop:text-xl font-bold font-sans">
                        <p>HIMPUNAN MAHASISWA STATISTIKA</p>
                        <p>(HIMSTAT) BINUS UNIVERSITY</p>
                    </div>
                </div>
                <div className="flex font-semibold font-sans flex-col gap-6 mt-4 desktop:flex-row desktop:justify-between desktop:mt-12 desktop:text-xl">
                    <div className="flex flex-col gap-y-1">
                        <p>Jl. K. H. Syahdan No. 9,</p>
                        <p>Kemanggisan, Palmerah</p>
                        <p>Jakarta 11480</p>
                        <p>Indonesia</p>
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <div className="flex gap-x-2">
                            <div className="pb-1"><i className="fa-solid fa-envelope"></i></div>
                            <div className="flex flex-col gap-y-1">
                                <p><a href="mailto:himstat@gmail.com" target="_blank" rel="noreferrer">himstat@gmail.com</a></p>
                                <p><a href="mailto:himstat@binus.ac.id" target="_blank" rel="noreferrer">himstat@binus.ac.id</a></p>
                            </div>
                        </div>
                        <div className="flex gap-x-2">
                            <div className="pb-1"><i className="fa-solid fa-phone"></i></div>
                            <div className="flex flex-col gap-y-1">
                                <a href="https://wa.me/628118441441" target="_blank" rel="noreferrer">
                                    <p>+62 8118 441 441</p>
                                </a>
                                <a href="https://wa.me/6282297970893" target="_blank" rel="noreferrer">
                                    <p>+62 8229 797 0893</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <a href="https://www.instagram.com/himstat" target="_blank" rel="noreferrer">
                            <p><i className="fa-brands fa-square-instagram"></i>&nbsp; himstat</p>
                        </a>
                        <a href="https://www.tiktok.com/@himstat" target="_blank" rel="noreferrer">
                            <p><i className="fa-brands fa-tiktok"></i>&nbsp; himstat</p>
                        </a>
                        <a href="https://www.linkedin.com/company/himstat-binus-university/?originalSubdomain=id" target="_blank" rel="noreferrer">
                            <p><i className="fa-brands fa-linkedin"></i>&nbsp; HIMSTAT BINUS UNIVERSITY</p>
                        </a>
                    </div>
                    <div className="flex select-none">
                        <Link to="/"><img src={`${process.env.PUBLIC_URL}/images/logo/logo-himstat.png`} alt="" className="w-16 desktop:w-32"/></Link>
                        <a href="https://ihmsinasional.com/" target="_blank" rel="noreferrer"><img src={`${process.env.PUBLIC_URL}/images/logo/logo-ihmsi.png`} alt="" className="w-16 desktop:w-32"/></a>
                    </div>
                </div>
                <div className="text-xs desktop:text-base font-medium mt-7 select-none font-sans">
                    <p>Copyright &copy; HIMSTAT 2024. All rights reserved.</p>
                </div>
            </footer>
        </div>
    )
}