import { useEffect, useState } from "react";

export default function Parallax({image, pos, height}) {

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setOffset(window.scrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <div className={`w-32 desktop:w-64 absolute ${pos}-5 desktop:${pos}-20 ${height} -z-10 select-none`} style={{transform: `translateY(${offset * 0.5}px) rotate(${offset * 0.1}deg)`}}>
            <img src={image} alt=""/>
        </div>
    )
}