import { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './CustomCarousel.css';
import { Link } from 'react-router-dom';

export default function CarouselComponent(highlights){

    const [activeItem, setActiveItem] = useState(0);

    // Ini requirement library react-multi-carousel
    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        },
    }

    const changeSlide = (previousSlide, currentSlide, dataSize) => {
        let activeSlide = 0
        // right arrow
        if (previousSlide < currentSlide) activeSlide = currentSlide - 2 === dataSize ? 0 : currentSlide - 2
        // left arrow
        else activeSlide = currentSlide + (currentSlide <= dataSize && currentSlide >= 2 ? -2 : dataSize - 2);
        setActiveItem(activeSlide);
    }

    return(
        <Carousel
            className='select-none min-h-[20vh] desktop:min-h-[70vh]'
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            shouldResetAutoplay={true}
            ssr={true}
            itemClass=''
            sliderClass='flex items-center justify-center'
            centerMode={true}
            draggable={false}
            focusOnSelect={true}
            arrows={false}
            customTransition='0.5s ease-in-out'
            afterChange={(previousSlide, { currentSlide }) => changeSlide(previousSlide, currentSlide, highlights.highlights.length)}
            >
            {highlights.highlights.map((h, index) => {
                const isArticle = h.ArticleId !== undefined;
                const isCustom = h.CustomHighlightId !== undefined;
                const id = isArticle ? h.ArticleId : h.EventId;
                const prefix = isArticle ? 'articles' : 'events';
                const link = isCustom ? (h.Link.startsWith('http://') || h.Link.startsWith('https://') ? h.Link : `https://${h.Link}`) : `/${prefix}/${id}`;
                const thumbnailPrefix = isCustom ? '/storage/CustomHighlightStorage/Image/' : isArticle ? '/storage/ArticleStorage/Thumbnail/' : '/storage/EventStorage/Thumbnail';
                const thumbnailLink = `${thumbnailPrefix}/${isCustom ? h.Image : h.Thumbnail}`;
                return(
                    <div 
                    key={index} 
                    className={`cursor-pointer transition-all relative w-[50vw] min-h-[10vh] desktop:min-h-[50vh] flex items-center justify-center
                        ${activeItem === index ? 'desktop:scale-95 desktop:hover:scale-[1]' : 'scale-90 grayscale'}`}>
                        {isCustom ? <a 
                            href={link} target='_blank' rel='noreferrer' 
                            className={`${activeItem === index ? '' : 'disabled pointer-events-none'}`}>
                                <img 
                                    src={process.env.REACT_APP_BASE_URL + thumbnailLink}
                                    style={{ transition: 'height 0.5s'}}
                                    className={`object-cover min-w-[50vw] rounded-lg desktop:rounded-3xl ${activeItem === index ? 'item-shadow h-[28vw]' : 'h-[20vw]'}`}
                                    alt={h.Title}>
                                </img>
                        </a> :
                        <Link 
                            to={link} 
                            className={`${activeItem === index ? '' : 'disabled pointer-events-none'}`}>
                                <img 
                                    src={process.env.REACT_APP_BASE_URL + thumbnailLink}
                                    style={{ transition: 'height 0.5s'}}
                                    className={`object-cover min-w-[50vw] rounded-3xl ${activeItem === index ? 'item-shadow h-[28vw]' : 'h-[20vw]'}`}
                                    alt={h.Title}>
                                </img>
                        </Link>}
                    </div>
                );
            })}
        </Carousel>
    )
}