import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EventDetails() {

    const nav = useNavigate();

    useEffect(() => {
        document.title = "Events - HIMSTAT";
    }, []);

    const {ID} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [event, setEvent] = useState([]);

    useEffect(() => {
        sessionStorage.removeItem('visit'); // Reset 'visit' on ID change

        if (!sessionStorage.getItem('visit')) {
            updateCounter(ID);
            sessionStorage.setItem('visit', 'x');
        }
    }, [ID]);
    
    const updateCounter = (id) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", `${process.env.REACT_APP_BASE_URL}/api/update-views/event/${id}`);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status !== 204) {
                    console.error('Failed to update views');
                }
            }
        };
        xhr.send();
    };

    const getEvent = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/event/${ID}`);
            const {event} = await response.data;
            if (event === null) {
                nav("/");
            }
            return event;
        } catch (error) {
            nav("/");
        } finally {
            setIsLoading(false);
        }
    }, [ID, nav]);
    
    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    useEffect(() => {
        getEvent().then((results) => {
            if(results) {
                results.Text = results.Text.replace(
                    /\/storage\/EventStorage\/TextImage\//g,
                    `${process.env.REACT_APP_BASE_URL}/storage/EventStorage/TextImage/`
                );
    
                results.Text = results.Text.replace(
                    /<ul>/g,
                    '<ul class="list-disc pl-6">'
                )
    
                results.Text = results.Text.replace(
                    /<ol>/g,
                    '<ol class="list-decimal pl-6">'
                )

                results.Text = results.Text.replace(
                    /<p>/g,
                    '<p class="break-words">'
                )

                results.Text = results.Text.replace(
                    /<a/g,
                    '<a class="text-blue decoration-blue visited:text-purple-600 visited:decoration-purple-600 underline"'
                )

                results.Text = results.Text.replace(
                    /<p\s+style="text-align:\s*center;\s*".*?>/g,
                    '<p style="text-align: center; display: flex; justify-content: center;">'
                );
    
                setEvent(results);
            }
        });
    }, [getEvent]);
    

    return(
        <div className="text-white p-8 desktop:px-80 desktop:py-16">
            {isLoading ? <div className="text-white"><p>Loading...</p></div> :
            <div>
                <div className="flex flex-col gap-6">
                    <h1 className="text-2xl desktop:text-6xl font-bold break-words">{event.Title}</h1>
                    <h5 className="text-xs desktop:text-base font-sans">{formatDate(event.DateCreated)}</h5>
                </div>
                <hr />
                <br />
                <div className="flex flex-col gap-10 items-center text-sm desktop:text-base font-sans text-justify">
                    <div dangerouslySetInnerHTML={{ __html: event.Text }} ></div>
                </div>
            </div>}
        </div>
    );
}