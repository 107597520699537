import { useCallback, useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
import { Tooltip } from "react-tooltip";

export default function Articles() {

    const nav = useNavigate();

    useEffect(() => {
        document.title = "Articles - HIMSTAT";
        Aos.init();
    }, []);

    const articleSortType = [
        "Latest",
        "Oldest",
        "Most Viewed"
    ]

    const [isLoading, setIsLoading] = useState(true);

    const scrollerRef = useRef(null);

    // Highlights
    const [articleHighlights, setArticleHighlights] = useState([]);

    // Search
    const [searchText, setSearchText] = useState('');
    const [inputValue, setInputValue] = useState('');

    // More Articles
    const [latestArticles, setLatestArticles] = useState([]);
    const [oldestArticles, setOldestArticles] = useState([]);
    const [mostViewedArticles, setMostViewedArticles] = useState([]);
    const [articles, setArticles] = useState([]);

    const [activeArticles, setActiveArticles] = useState("Latest"); // Sorting di bagian "More Articles"
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const getArticleHighlights = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/article-highlights`);
            return response.data;
        } catch (error) {
            nav('/');
        }
    }, [nav]);

    useEffect(() => {
        getArticleHighlights().then((results) => {
            setArticleHighlights(results);
        })
    }, [getArticleHighlights]);

    useEffect(() => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollIntoView();
        }
    }, [articles]);

    const getArticles = useCallback( async () => {
        setIsLoading(true);
        try {
            if(searchText.length > 0){
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/articles?search=${searchText}`);
                return response.data;
            }
            else{
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/articles?page=${currentPage}`);
                return response.data;
            }
        } catch (error) {
            nav('/');
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, searchText, nav]);

    const handleSearch = (event) => {
        event.preventDefault();
        setSearchText(inputValue);
    }

    useEffect(() => {
        getArticles().then((result) => {
            if(result){
                setLatestArticles(result.latestArticles.data);
                setOldestArticles(result.oldestArticles.data);
                setMostViewedArticles(result.mostViewedArticles.data);
                setPageCount(result.latestArticles["last_page"]);
                if(searchText.length > 0){
                    setActiveArticles('');
                    setArticles(result.articles);
                }
                else if(searchText.length === 0 && activeArticles === '') {
                    setActiveArticles("Latest");
                    setArticles(result.latestArticles.data);
                }
            }
        });
    }, [getArticles, currentPage, searchText, activeArticles]);

    useEffect(() => {
        if(activeArticles === "Latest") setArticles(latestArticles);
        else if(activeArticles === "Oldest") setArticles(oldestArticles);
        else if(activeArticles === "Most Viewed") setArticles(mostViewedArticles);
    }, [activeArticles, latestArticles, mostViewedArticles, oldestArticles]);

    const handlePageChange = (data) => {
        setCurrentPage(data.selected + 1);
    };

    return(
        <div className="overflow-hidden">
            {/* HIGHLIGHTS */}
            <div className="flex flex-col justify-center desktop:items-center gap-10">
                <h3 className="absolute top-20 right-0 text-5xl desktop:text-8xl text-white/10 font-bold">ARTICLES</h3>
                <h3 className="absolute top-28 left-0 pl-6 pr-3 desktop:pl-36 desktop:pr-6 bg-white text-midnight text-3xl desktop:text-5xl font-bold py-2">HIGHLIGHTS</h3>
                {articleHighlights.length > 0 ? 
                    <div className='flex justify-center font-sans mt-36'>
                        <Link to={`/articles/${articleHighlights[0].ArticleId}`} className='hidden desktop:flex flex-col gap-4 justify-center items-start p-6 bg-white text-midnight rounded-2xl w-2/5'>
                            <img src={process.env.REACT_APP_BASE_URL + "/storage/ArticleStorage/Thumbnail/" + articleHighlights[0].Thumbnail} alt="thumbnail" className='min-h-[550px] object-cover rounded-2xl'/>
                            <div className='flex flex-col gap-8 min-w-full'>
                                <h4
                                data-tooltip-id="article-tooltip"
                                data-tooltip-content={articleHighlights[0].Title}
                                data-tooltip-delay-show={500}
                                data-tooltip-variant="dark"
                                data-tooltip-place="bottom"
                                data-tooltip-delay-hide={0}
                                className='text-2xl font-semibold line-clamp-2'>{articleHighlights[0].Title}</h4>
                                <div className='flex justify-between items-center'>
                                    <h5>{articleHighlights[0].author.Name}</h5>
                                    <h5>{formatDate(articleHighlights[0].DateCreated)}</h5>
                                </div>
                            </div>
                        </Link>

                        <div className='desktop:w-2/5 flex flex-col items-center gap-4 desktop:justify-between mx-2.5 desktop:mx-12 h-inherit'>
                            {articleHighlights.map((articleHighlight, index) => (
                                <Link to={`/articles/${articleHighlight.ArticleId}`} key={index} className={`flex ${index === 0 ? 'desktop:hidden' : ''} bg-white text-midnight p-2.5 desktop:p-6 rounded-2xl gap-4 desktop:gap-6 min-w-full`}>
                                    <img src={process.env.REACT_APP_BASE_URL + "/storage/ArticleStorage/Thumbnail/" + articleHighlight.Thumbnail} alt="thumbnail" className='w-24 min-h-24 tablet:w-36 tablet:min-h-36 desktop:w-44 desktop:min-h-44 rounded-2xl object-cover'/>
                                    <div className='flex flex-col justify-between'>
                                        <div className='flex flex-col gap-1'>
                                            <h4
                                            data-tooltip-id="article-tooltip"
                                            data-tooltip-content={articleHighlight.Title}
                                            data-tooltip-delay-show={500}
                                            data-tooltip-variant="dark"
                                            data-tooltip-place="bottom"
                                            data-tooltip-delay-hide={0}
                                            className='text-sm tablet:text-lg desktop:text-xl font-semibold line-clamp-2'>{articleHighlight.Title}</h4>
                                            <h5 className='text-xs tablet:text-base desktop:text-lg font-medium'>{articleHighlight.author.Name}</h5>
                                        </div>
                                        <h5 className='text-xs tablet:text-base desktop:text-lg font-medium'>{formatDate(articleHighlight.DateCreated)}</h5>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div> : isLoading ? <div className="flex justify-center text-white mt-36"><p>Loading...</p></div> : 
                    <div className="text-white mt-36 mx-12">
                        <h2 className="text-xl font-semibold">No Highlights Available</h2>
                        <p className="mt-4">It looks like there are no highlights available at the moment. Check back later or explore other sections of our site.</p>
                </div>
                }
            </div>

            {/* MORE ARTICLES */}
            <div className="flex flex-col bg-white text-midnight px-8 py-4 desktop:px-36 desktop:py-12 mt-24 min-h-screen">
                <div id="header" className="sticky top-0 bg-white flex flex-col gap-4 py-4">
                    <h1 ref={scrollerRef} id="scroller" className="text-3xl desktop:text-6xl font-bold">MORE ARTICLES</h1>

                    {/* Search Bar Mobile */}
                    {articles.length > 0 && <form onSubmit={handleSearch} className="flex desktop:hidden justify-between items-center py-1.5 px-5 bg-white rounded-full border border-midnight">
                        <input className="bg-transparent focus:outline-none w-11/12" type="text" placeholder="Search" value={inputValue} onChange={(e) => setInputValue(e.target.value)} name="searchInput"/>
                        <button><i class="fa-solid fa-magnifying-glass"></i></button>
                    </form>}
                </div>
                <div className="flex justify-between font-sans">
                    <div className="flex flex-col gap-4 desktop:gap-10">
                        <div className={`sticky top-28 desktop:top-20 py-2 bg-white flex gap-8 desktop:gap-12 text-xs desktop:text-xl`}>
                            {articleSortType.map((type, index) => (
                                <h4 key={index}
                                className={`cursor-pointer select-none ${activeArticles === type ? "font-semibold border-b-2 border-midnight" : ""}`}
                                onClick={() => {
                                    if(activeArticles !== type){
                                        setActiveArticles(type);
                                        setCurrentPage(1);
                                        setSearchText('');
                                        setInputValue('');
                                        document.getElementById('scroller').scrollIntoView({behavior: "smooth"});
                                    }
                                }}>{type}</h4>
                            ))}
                        </div>
                        <div className="flex flex-col gap-6 my-4 desktop:mt-0">
                            {isLoading ? <div><p>Loading...</p></div> :
                            articles.length > 0 ? articles.map((article, index) => (
                                <Link data-aos="fade-up" to={`/articles/${article.ArticleId}`} key={index} className='flex bg-white text-midnight p-2.5 desktop:p-6 rounded-2xl gap-4 desktop:gap-6 max-w-2xl'>
                                    <img src={process.env.REACT_APP_BASE_URL + "/storage/ArticleStorage/Thumbnail/" + article.Thumbnail} alt="thumbnail" className='object-cover w-24 h-24 tablet:w-36 tablet:h-36 desktop:w-44 desktop:h-44 rounded-2xl'/>
                                    <div className='flex flex-col justify-between'>
                                        <div className='flex flex-col gap-1'>
                                            <h4
                                            data-tooltip-id="article-tooltip"
                                            data-tooltip-content={article.Title}
                                            data-tooltip-delay-show={500}
                                            data-tooltip-variant="dark"
                                            data-tooltip-place="bottom"
                                            data-tooltip-delay-hide={0}
                                            className='text-sm tablet:text-lg desktop:text-xl font-semibold text-wrap line-clamp-3'>{article.Title}</h4>
                                            <h5 className='text-xs tablet:text-base desktop:text-lg font-medium'>{article.author.Name}</h5>
                                        </div>
                                        <h5 className='text-xs tablet:text-base desktop:text-lg font-medium'>{formatDate(article.DateCreated)}</h5>
                                    </div>
                                </Link>
                            )) : searchText.length > 0 ?
                            <div className="w-3/4">
                                <h2 className="text-xl font-semibold">No Results Found</h2>
                                <p className="mt-4">We couldn't find any articles matching your search criteria. Please try different keywords or check out other sections of our site.</p>
                            </div> :
                            <div className="w-3/4">
                                <h2 className="text-xl font-semibold">No Articles Available</h2>
                                <p className="mt-4">It looks like there are no articles available at the moment. Check back later or explore other sections of our site.</p>
                            </div>}
                        </div>

                        {activeArticles !== '' &&  articles.length > 0 &&
                        <ReactPaginate
                            previousLabel=""
                            nextLabel=""
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            containerClassName="flex gap-2 desktop:gap-4 justify-center items-center"
                            previousClassName="text-midnight"
                            previousLinkClassName="fa-solid fa-arrow-left"
                            nextClassName="text-midnight"
                            nextLinkClassName="fa-solid fa-arrow-right"
                            pageClassName="rounded-full w-8 desktop:w-10 h-8 desktop:h-10 flex justify-center items-center text-midnight font-semibold"
                            pageLinkClassName="rounded-full border-2 border-midnight w-8 desktop:w-10 h-8 desktop:h-10 flex justify-center items-center hover:bg-midnight/30 transition-all"
                            breakClassName="text-midnight"
                            activeClassName="bg-midnight"
                            activeLinkClassName="text-white hover:none"
                            onPageChange={handlePageChange}
                            forcePage={currentPage - 1}
                        />}
                    </div>

                    {/* HIGHLIGHTS DI BAGIAN KANANNYA MORE ARTICLES (di mobile gaada) */}
                    <div className="hidden desktop:block w-2/5">
                        <div className="sticky top-20 flex flex-col gap-10">

                            {/* Search Bar Desktop */}
                            <form onSubmit={handleSearch} className="flex justify-between items-center py-2 px-5 bg-white rounded-full border border-midnight">
                                <input className="bg-transparent focus:outline-none w-11/12" type="text" placeholder="Search" value={inputValue} onChange={(e) => setInputValue(e.target.value)} name="searchInput"/>
                                <button><i class="fa-solid fa-magnifying-glass"></i></button>
                            </form>
                            {articleHighlights.length > 0 && <div className="flex flex-col items-start">
                                <h4 className="py-1 px-2.5 rounded-t-2xl bg-midnight text-white font-semibold"><i class="fa-solid fa-fire"></i> Highlights</h4>
                                <div className="flex flex-col gap-6 p-6 border border-midnight rounded-e-xl rounded-bl-xl w-full">
                                    {articleHighlights.map((articleHighlight, index) => (
                                        <Link to={`/articles/${articleHighlight.ArticleId}`} key={index} className="flex gap-4">
                                            <img className="w-24 min-h-16 rounded-lg" src={process.env.REACT_APP_BASE_URL + "/storage/ArticleStorage/Thumbnail/" + articleHighlight.Thumbnail} alt="thumbnail" />
                                            <div className="flex flex-col justify-between">
                                                <div className="flex flex-col">
                                                    <h4
                                                    data-tooltip-id="article-tooltip"
                                                    data-tooltip-content={articleHighlight.Title}
                                                    data-tooltip-delay-show={500}
                                                    data-tooltip-variant="dark"
                                                    data-tooltip-place="bottom"
                                                    data-tooltip-delay-hide={0}
                                                    className="font-semibold line-clamp-2">{articleHighlight.Title}</h4>
                                                    <h5 className="text-xs">oleh {articleHighlight.author.Name}</h5>
                                                </div>
                                                <h5 className="text-xs">{formatDate(articleHighlight.DateCreated)}</h5>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <Tooltip id="article-tooltip" />
        </div>
    )
}