export const dpi = [
    {
        name: 'Kairos Abinaya Susanto',
        position: 'President'
    },
    {
        name: 'Jesslyn Ong',
        position: 'Treasurer'
    },
    {
        name: 'Putri Nurhaliza',
        position: 'Secretary'
    },
]

export const lrd = [
    {
        name: 'Farhan Armandy Rasyid',
        position: 'LRD Director'
    },
    {
        name: 'Jeson Adhi Dharma',
        position: 'Head of HRDC'
    },
    {
        name: 'Matthew Owen',
        position: 'Head of ET'
    },
]

export const hrdc = [
    {
        name: 'Jeson Adhi Dharma',
        position: 'Head of HRDC'
    },
    {
        name: 'Amanda Sugito',
        position: 'HRDC Staff'
    },
    {
        name: 'Anthony',
        position: 'HRDC Staff'
    },
    {
        name: 'M. Aufa Mumtaza Ibadillah',
        position: 'HRDC Staff'
    },
    {
        name: 'Elizabeth Sonia Kristanty Marpaung',
        position: 'HRDC Staff'
    },
    {
        name: 'Thaddeus Kendrick Andrian',
        position: 'HRDC Staff'
    },
]

export const et = [
    {
        name: 'Matthew Owen',
        position: 'Head of ET'
    },
    {
        name: 'Joycelin',
        position: 'ET Staff'
    },
    {
        name: 'Nusantara Kusuma',
        position: 'ET Staff'
    },
    {
        name: 'Ricardo Xavier Rinzi',
        position: 'ET Staff'
    },
    {
        name: 'Kalyani Jeslyn Lim',
        position: 'ET Staff'
    },
    {
        name: 'Lambert Aditama Soehardjianto',
        position: 'ET Staff'
    },
    {
        name: 'Wilsen Soetresno',
        position: 'ET Staff'
    },
]

export const mic = [
    {
        name: 'Jevant Russell',
        position: 'MIC Director'
    },
    {
        name: 'Darrien Rafael Wijaya',
        position: 'Head of VCD'
    },
    {
        name: 'Michelle Savinka',
        position: 'Head of PR'
    },
    {
        name: 'George Maximillian Theodore',
        position: 'Head of WebDev'
    },
]

export const vcd = [
    {
        name: 'Darrien Rafael Wijaya',
        position: 'Head of VCD'
    },
    {
        name: 'Asyifa Izzatil Isma',
        position: 'VCD Staff'
    },
    {
        name: 'Britney Angeline Soeseno',
        position: 'VCD Staff'
    },
    {
        name: 'Hazel Zaki Adityo',
        position: 'VCD Staff'
    },
    {
        name: 'Nadya Angelie Lislie',
        position: 'VCD Staff'
    },
    {
        name: 'Jeremi Reynald',
        position: 'VCD Staff'
    },
    {
        name: 'Rasya Nuhaifa Movia',
        position: 'VCD Staff'
    },
]

export const pr = [
    {
        name: 'Michelle Savinka',
        position: 'Head of PR'
    },
    {
        name: 'Bella Nadya Aurelia',
        position: 'PR Staff'
    },
    {
        name: 'Vanessa Santoso',
        position: 'PR Staff'
    },
    {
        name: 'Diandra Lestriani Humairah',
        position: 'PR Staff'
    },
    {
        name: 'Muhammad Indra Ferdinand',
        position: 'PR Staff'
    },
    {
        name: 'Regina Lo',
        position: 'PR Staff'
    },
]

export const web = [
    {
        name: 'George Maximillian Theodore',
        position: 'Head of WebDev (Project Manager and Front End Developer)'
    },
    {
        name: 'Clement Nathanael',
        position: 'WebDev Staff (UI/UX Designer)'
    },
    {
        name: 'Ryu Orlando Tamin',
        position: 'WebDev Staff (Back End Developer)'
    },
]