import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export default function Programs(){

    const nav = useNavigate();

    useEffect(() => {
        document.title = "Programs - HIMSTAT";
        Aos.init();
    }, []);

    const [programs, setPrograms] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [delayedProgram, setDelayedProgram] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getPrograms = useCallback ( async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/programs`);
            const {programs} = await response.data;
            return programs;
        } catch (error) {
            nav('/');
        } finally {
            setIsLoading(false);  // Set loading to false after fetching data
        }
    }, [nav]);

    const handleProgramClick = (index) => {
        if (selectedProgram === index) {
            setSelectedProgram(null);
            setDelayedProgram(null);
        } else {
            setSelectedProgram(index);
            setDelayedProgram(null);
        }
    };

    useEffect(() => {
        if (selectedProgram !== null) {
            const timer = setTimeout(() => {
                setDelayedProgram(selectedProgram);
            }, 100); // Delay to allow the close transition to complete
            return () => clearTimeout(timer);
        }
    }, [selectedProgram]);

    useEffect(() => {
        getPrograms().then((results) => {
            if(results) {
                results.forEach((program) => {
                    program.Text = program.Text.replace(
                        /\/storage\/ProgramStorage\/TextImage\//g, // (/../g) -> global flag -> works for all instances of the text
                        `${process.env.REACT_APP_BASE_URL}/storage/ProgramStorage/TextImage/`
                    );

                    program.Text = program.Text.replace(
                        /<ul>/g,
                        '<ul class="list-disc pl-6">'
                    )
        
                    program.Text = program.Text.replace(
                        /<ol>/g,
                        '<ol class="list-decimal pl-6">'
                    )
    
                    program.Text = program.Text.replace(
                        /<p>/g,
                        '<p class="break-words">'
                    )
    
                    program.Text = program.Text.replace(
                        /<a/g,
                        '<a class="text-blue decoration-blue visited:text-purple-600 visited:decoration-purple-600 underline"'
                    )
    
                    program.Text = program.Text.replace(
                        /<p\s+style="text-align:\s*center;\s*".*?>/g,
                        '<p style="text-align: center; display: flex; justify-content: center;">'
                    );
                });
                setPrograms(results);
            }
        });

        // Langsung ada program yang di show kalau di laptop
        if(window.innerWidth > 1024) {
            setSelectedProgram(0);
        }
    }, [getPrograms]);

    return(
        <div className="">
            {/* LARGE DESKTOP */}
            <div className="hidden large-desktop:flex flex-col items-center mx-40 my-16 gap-16 text-white">
                <h1 data-aos="fade-up" className="text-3xl large-desktop:text-7xl font-bold">OUR PROGRAMS</h1>
                {programs.length > 0 ? <div className="flex gap-4 min-w-full">
                    <div className="flex flex-col items-end gap-4 w-1/3 select-none">
                        {programs.map((program, index) => (
                            <div key={index} className={`${selectedProgram === index ? "w-full" : "bg-glass-border p-0.5 rounded-2xl w-11/12"}`} style={{transition: "width 0.3s"}}>
                                <div className={`${selectedProgram === index ? "" :"bg-midnight rounded-[14px]"}`}>
                                    <div className={`flex justify-center px-12 py-3 rounded-xl cursor-pointer min-h-20 items-center ${selectedProgram === index ? "bg-white" : "bg-glass"}`} onClick={() => {
                                        setSelectedProgram(index);
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        });
                                        }}>
                                        <h2 
                                        data-tooltip-id="program-tooltip"
                                        data-tooltip-content={program.Title}
                                        data-tooltip-delay-show={500}
                                        data-tooltip-variant="dark"
                                        data-tooltip-place="bottom"
                                        data-tooltip-delay-hide={0}
                                        className={`text-2xl text-center font-bold line-clamp-2 ${selectedProgram === index ? "text-midnight" : ""}`}>{program.Title}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex bg-glass-border p-0.5 rounded-2xl w-2/3">
                        <div className="flex bg-midnight rounded-[14px] w-full">
                            <div className={`flex flex-col items-center gap-6 px-16 py-8 rounded-xl bg-glass w-full`}>
                                <h1 className="text-5xl font-bold text-center">{programs[selectedProgram]?.Title}</h1>
                                <img src={process.env.REACT_APP_BASE_URL + '/storage/ProgramStorage/Thumbnail/' + programs[selectedProgram]?.Thumbnail} alt={programs[selectedProgram]?.Title} className="w-64"/>
                                <div dangerouslySetInnerHTML={{ __html: programs[selectedProgram]?.Text }} className="font-sans text-justify"></div>
                            </div>
                        </div>
                    </div>
                </div> : isLoading ? <div className="text-white"><p>Loading...</p></div> :
                <div className="text-white">
                    <h2 className="text-xl font-semibold">No Programs Available</h2>
                    <p className="mt-4">It looks like there are no programs available at the moment. Check back later or explore other sections of our site.</p>
                </div>}
            </div>

            {/* MOBILE - DESKTOP */}
            <div className="flex flex-col gap-4 large-desktop:hidden my-20 mx-10 text-center text-white">
                <h1 data-aos="fade-up" className="text-3xl font-bold">OUR PROGRAMS</h1>
                {programs.length > 0 ? <div className="flex flex-col gap-2 my-16">
                    {programs.map((program, index) => (
                        <div className="flex flex-col gap-2">
                            {/* Dropdown */}
                            <div className={`${selectedProgram === index ? "" : "bg-glass-border p-0.5 rounded-2xl"}`}>
                                <div className={`${selectedProgram === index ? "" :"bg-midnight rounded-[14px]"}`}>
                                    <div 
                                        className={`flex justify-center items-center rounded-2xl h-14 ${selectedProgram === index ? "bg-white text-midnight" : "bg-glass"} backdrop-blur-lg`} 
                                        onClick={() => {handleProgramClick(index)}}>
                                        <h2 className={`text-xs desktop:text-xl px-11 font-sans font-bold line-clamp-2`}>
                                            {program.Title}
                                        </h2>
                                        <i class={`absolute right-3 top-5 fa-solid ${selectedProgram === index ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                                    </div>
                                </div>
                            </div>
                            {/* Dropdown Content */}
                            <div className={`overflow-hidden ${delayedProgram  === index ? "max-h-full" : "max-h-0"}`} 
                            style={{transition: "max-height 0.5s ease-in-out"}}>
                                <div className={`flex bg-glass-border p-0.5 rounded-2xl`}>
                                    <div className="flex bg-midnight rounded-[14px] w-full">
                                        <div className="flex flex-col items-center gap-3 px-6 py-4 rounded-xl bg-glass w-full">
                                            <h2 className="text-2xl font-bold">{program.Title}</h2>
                                            <img src={process.env.REACT_APP_BASE_URL + '/storage/ProgramStorage/Thumbnail/' + program.Thumbnail} alt="" className="w-32"/>
                                            <div dangerouslySetInnerHTML={{ __html: program.Text }} className="text-sm font-sans text-justify"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> : isLoading ? <div className="text-white"><p>Loading...</p></div> :
                <div className="text-white">
                    <h2 className="text-xl font-semibold">No Programs Available</h2>
                    <p className="mt-4">It looks like there are no programs available at the moment. Check back later or explore other sections of our site.</p>
                </div>
                }
            </div>
            <Tooltip id="program-tooltip" />
        </div>
    )
}