import { useCallback, useEffect, useState } from 'react';
import CarouselComponent from '../Components/CarouselComponent';
import Parallax from '../Components/Parallax';
import Circle from '../Components/Circle';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'scrollmagic/scrollmagic/uncompressed/ScrollMagic';

export default function Home() {

    useEffect(() => {
        document.title = "HIMSTAT";
        Aos.init();
    }, []);

    // List gambar mission
    const missionImages = [
        process.env.PUBLIC_URL + 'images/svg/mission1.svg',
        process.env.PUBLIC_URL + 'images/svg/mission2.svg',
        process.env.PUBLIC_URL + 'images/svg/mission3.svg',
        process.env.PUBLIC_URL + 'images/svg/mission4.svg'
    ]

    // Buat patokan gambar di slide show events
    const [images, setImages] = useState({
        img1: 1,
        img2: 13,
        img3: 25,
        img4: 37,
    });

    const [activeMission, setActiveMission] = useState(0);
    const [missionText, setMissionText] = useState('');
    const [opacity, setOpacity] = useState(1);

    // Auto geser mission tiap 3 detik
    useEffect(() => {
        const timer = setTimeout(() => {
            setActiveMission(prevMission => 
                prevMission === missionImages.length - 1 ? 0 : prevMission + 1
            );
        }, 3000);
    
        return () => clearTimeout(timer);
    }, [activeMission, missionImages.length]);

    // Buat efek fade in/out di bagian mission
    useEffect(() => {
        setOpacity(0);

        setTimeout(() => {
            switch (activeMission) {
                case 0:
                    setMissionText('Melaksanakan kegiatan pengajaran, pelatihan, serta kompetisi bagi seluruh mahasiswa program studi ganda Teknik Informatika dan Statistika BINUS University untuk mengembangkan kemampuan akademik dan nonakademik.');
                    break;
                case 1:
                    setMissionText('Berkontribusi melalui kegiatan pelayanan dan pengabdian kepada masyarakat dalam bidang keilmuan teknik informatika dan statistika.');
                    break;
                case 2:
                    setMissionText('Membangun dan memperkuat kekeluargaan antaranggota melalui kegiatan dan program kerja yang mengedepankan kolaborasi, kebersamaan, dan kerja sama.');
                    break;
                case 3:
                    setMissionText('Membina hubungan dan kerja sama dengan pihak eksternal, seperti organisasi kemahasiswaan, mitra sponsor, dan asosiasi keilmuan statistika di Indonesia.');
                    break;
                default:
                    setMissionText('');
            }
            setOpacity(1);
        }, 300);
    }, [activeMission]);

    const [highlights, setHighlights] = useState([]);
    const [articleHighlights, setArticleHighlights] = useState([]);

    const getHiglights = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard-highlights');
            return response.data;
        } catch (error) {
            return [];
        }
    };

    const getArticleHighlights = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/article-highlights`);
            return response.data;
        } catch (error) {
            return [];
        }
    };
    
    useEffect(() => {
        getHiglights().then((result) => {
            setHighlights(result);
        });
        getArticleHighlights().then((results) => {
            setArticleHighlights(results);
        });
    }, []);

    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    function getRandomNumber (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const getDifferentRandomNumber = useCallback((currentNumber, min, max) => {
        let newNumber;
        do {
            newNumber = getRandomNumber(min, max);
        } while (newNumber === currentNumber);
        return newNumber;
    }, []);

    // Event pictures transition
    useEffect(() => {
        const interval1 = setInterval(() => {
            setTimeout(() => {
                setTimeout(() => {
                    setImages(prevImages => {
                        const newImg1 = getDifferentRandomNumber(prevImages.img1, 1, 12);
                        return {
                            ...prevImages,
                            img1: newImg1
                        };
                    });
                }, 50);
            }, 1500);
        }, 6000);

        const interval2 = setInterval(() => {
            setTimeout(() => {
                setTimeout(() => {
                    setImages(prevImages => {
                        const newImg2 = getDifferentRandomNumber(prevImages.img2, 13, 24);
                        return {
                            ...prevImages,
                            img2: newImg2
                        };
                    });
                }, 50);
            }, 3000);
        }, 6000);

        const interval3 = setInterval(() => {
            setTimeout(() => {
                setTimeout(() => {
                    setImages(prevImages => {
                        const newImg3 = getDifferentRandomNumber(prevImages.img3, 25, 36);
                        return {
                            ...prevImages,
                            img3: newImg3
                        };
                    });
                }, 50);
            }, 4500);
        }, 6000);

        const interval4 = setInterval(() => {
            setTimeout(() => {
                setTimeout(() => {
                    setImages(prevImages => {
                        const newImg4 = getDifferentRandomNumber(prevImages.img4, 37, 48);
                        return {
                            ...prevImages,
                            img4: newImg4
                        };
                    });
                }, 50);
            }, 6000);
        }, 6000);

        return () => {
            clearInterval(interval1);
            clearInterval(interval2);
            clearInterval(interval3);
            clearInterval(interval4);
        };
    }, [getDifferentRandomNumber]);

    return(
        <div className={`sticky text-white overflow-hidden pb-16`}>
            {/* Background Particles */}
            <Circle x="-translate-x-1/2"></Circle>
            <Circle y="translate-y-[50%]" x="translate-x-2/3"></Circle>
            <Circle y="translate-y-[150%]" x="-translate-x-1/2"></Circle>
            <Parallax image={process.env.PUBLIC_URL + 'images/parallax-texture/2.png'} pos={"right"}></Parallax>
            <Parallax image={process.env.PUBLIC_URL + 'images/parallax-texture/3.png'} pos={"left"} height={"top-[400px]"}></Parallax>
            <Parallax image={process.env.PUBLIC_URL + 'images/parallax-texture/1.png'} pos={`right`} height={"top-[800px]"}></Parallax>
            { window.innerWidth > 1024 &&
                <div>
                    <Parallax image={process.env.PUBLIC_URL + 'images/parallax-texture/3.png'} pos={`left`} height={"top-[1200px]"}></Parallax>
                    <Parallax image={process.env.PUBLIC_URL + 'images/parallax-texture/2.png'} pos={`right`} height={"top-[1600px]"}></Parallax>
                </div>
            }

            <div data-aos="fade-up" className="flex items-center justify-center gap-4 mt-4 select-none">
                <div className="flex flex-col items-center">
                    <h1 className="font-bold text-4xl tablet:text-7xl ultra-large-desktop:text-9xl">HIMSTAT</h1>
                    <h2 className="font-medium text-[10px] tablet:text-xl ultra-large-desktop:text-3xl">HIMPUNAN MAHASISWA STATISTIKA</h2>
                </div>
                <Link to="/"><img src={process.env.PUBLIC_URL + 'images/logo/logo-himstat.png'} alt="Logo HIMSTAT" className="w-12 tablet:w-24 ultra-large-desktop:w-36 bg-silver rounded-xl p-1"/></Link>
                <a href="https://ihmsinasional.com/" target='_blank' rel='noreferrer'><img src={process.env.PUBLIC_URL + 'images/logo/logo-ihmsi.png'} alt="Logo IHMSI" className="w-12 tablet:w-24 ultra-large-desktop:w-36 bg-silver rounded-xl p-1 desktop:p-2"/></a>
            </div>
            <div data-aos="fade-up" className="flex justify-center mt-8">
                <a href="https://wa.me/628118441441" target="_blank" rel='noreferrer' className="rounded-full hover:bg-silver hover:text-midnight transition-all hover:scale-105 border px-6 py-2 desktop:border-[2px] desktop:px-10 desktop:py-2 ultra-large-desktop:px-14 ultra-large-desktop:py-4 desktop:text-xl ultra-large-desktop:text-4xl text-nowrap">Reach Us!</a>
            </div>
        
            {highlights.length > 0 && <div data-aos="fade-up" className='my-4'>
                <CarouselComponent highlights={highlights}></CarouselComponent>
            </div>}

            <div className='flex flex-col justify-center items-center gap-4 my-8 mx-16 desktop:gap-12 desktop:my-32 desktop:mx-60'>
                <div data-aos="fade-up" className='flex select-none gap-2 desktop:gap-8'>
                    <h1 className='font-bold text-6xl desktop:text-9xl'>ABOUT</h1>
                    <h1 className='font-bold text-transparent text-6xl desktop:text-9xl' style={{WebkitTextStrokeWidth: '2px', WebkitTextStrokeColor: '#F2F2F3'}}>US</h1>
                </div>
                <div data-aos="fade-up" className='flex gap-6 justify-center items-center flex-col desktop:flex-row'>
                    <iframe className='w-full desktop:min-w-[25vw] aspect-video rounded-lg' src="https://www.youtube.com/embed/GAb3raS8beg" title="Himpunan Mahasiswa Statistika (HIMSTAT) 2022 - 2023 - Student Association" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"></iframe>
                    <p className='text-xs text-justify font-sans desktop:text-xl ultra-large-desktop:text-3xl'>Himpunan Mahasiswa Statistika BINUS University atau yang disingkat menjadi <b>HIMSTAT BINUS University</b> merupakan Organisasi Kemahasiswaan di Universitas Bina Nusantara berbentuk Himpunan Mahasiswa Jurusan (HMJ) yang didirikan pada tanggal 11 September 2000. HIMSTAT BINUS University adalah organisasi yang bersifat kemahasiswaan, keilmuan, kekeluargaan, non-politik, dan dinamis.</p>
                </div>
                <div className='min-w-full'>
                    <p data-aos="fade-up" className='text-xs text-justify font-sans desktop:text-xl ultra-large-desktop:text-3xl'>Tujuan dibentuknya HIMSTAT BINUS University adalah sebagai <b>wadah</b> bagi para mahasiswa jurusan ganda <b>Teknik Informatika dan Statistika</b> BINUS University untuk <b>belajar dan melatih soft skill</b> dengan cara berorganisasi, meningkatkan prestasi akademik mahasiswa jurusan ganda Teknik Informatika dan Statistika BINUS University, serta meningkatkan <b>minat dan apresiasi</b> seluruh mahasiswa BINUS University serta masyarakat umum terhadap ilmu Statistika.</p>
                </div>
            </div>

            <h3 data-aos="fade-left" className='absolute -right-2 desktop:-right-5 font-bold text-white/15 select-none text-7xl desktop:text-9xl'>VISION</h3>

            <div className='flex flex-col items-center'>
                <div data-aos="fade-up" className='bg-glass-border p-0.5 rounded-2xl mx-12 desktop:mx-24 max-w-screen-lg ultra-large-desktop:max-w-screen-xl'>
                    <div className="bg-midnight rounded-[14px]">
                        <div className='flex items-center rounded-xl bg-glass backdrop-blur-lg'>
                            <h4 className='absolute -top-4 desktop:-top-8 left-6 desktop:left-14 font-bold text-2xl desktop:text-6xl'>VISION</h4>
                            <p className='text-justify font-sans text-xs px-6 py-4 desktop:text-xl ultra-large-desktop:text-3xl desktop:px-14 desktop:py-9'>Menjadikan Himpunan Mahasiswa Statistika BINUS University sebagai organisasi kemahasiswaan yang mewadahi pengembangan ilmu pengetahuan, keterampilan, dan kekeluargaan, serta aktif dalam pengabdian kepada masyarakat bagi mahasiswa program studi ganda Teknik Informatika dan Statistika untuk menjadi organisasi yang dikenal unggul dalam skala nasional pada tahun 2027.</p>
                        </div>
                    </div>
                </div>
            </div>
        
            <h3 data-aos="fade-right" className='absolute text-7xl -left-2 desktop:-left-5 desktop:text-9xl font-bold text-white/15 select-none'>MISSION</h3>

            <div className='flex flex-col my-10 desktop:my-24 justify-center items-center gap-20'>
                <div data-aos="fade-up" className='flex gap-8 desktop:gap-20'>
                    {missionImages.map((mission, index) => (
                        <img key={index} src={mission} alt={`Mission ${index}`} className={`w-12 desktop:w-28 cursor-pointer transition-all duration-1000 ${index === activeMission ? 'drop-shadow-mission' : 'opacity-70'}`} onClick={() => setActiveMission(index)}/>
                    ))}
                </div>
                <div data-aos="fade-up" className='bg-glass-border p-0.5 rounded-2xl mx-12 desktop:mx-24 max-w-screen-lg ultra-large-desktop:max-w-screen-xl'>
                    <div className="bg-midnight rounded-[14px]">
                        <h4 className='absolute z-20 -top-4 desktop:-top-8 right-6 desktop:right-14 font-bold text-2xl desktop:text-6xl'>MISSION</h4>
                        <div className={`flex items-center rounded-2xl bg-glass backdrop-blur-lg min-h-24 desktop:min-h-44`}>
                            <p className={`text-justify font-sans text-xs px-6 py-4 desktop:text-xl ultra-large-desktop:text-3xl desktop:px-14 desktop:py-9 transition-opacity duration-500 opacity-${opacity}`}>
                                {missionText}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" className='p-10 desktop:p-20 select-none'>
                <Link to="/structure" className='flex flex-col'>
                    <img className='absolute top-0 left-0 -z-10 object-cover w-full h-full grayscale opacity-50' src={process.env.PUBLIC_URL + '/images/organizational-structure/organization-background.jpg'} alt="HIMSTAT" />
                    <img src={process.env.PUBLIC_URL + 'images/svg/ORGANIZATIONAL.svg'} alt='ORGANIZATIONAL'/>
                    <div className='flex desktop:gap-24 items-center'>
                        <h1 className='text-3xl tablet:text-7xl desktop:text-8xl large-desktop:text-9xl font-bold'>STRUCTURE</h1>
                        <div className='flex flex-col justify-center items-center desktop:gap-4'>
                            <img src={process.env.PUBLIC_URL + 'images/svg/organization-arrow.svg'} alt='Click to view' className='w-1/2 desktop:w-full'/>
                            <h5 className='text-xs desktop:text-2xl large-desktop:text-3xl ultra-large-desktop:text-4xl'>Click to view</h5>
                        </div>
                    </div>
                </Link>
            </div>

            <h3 data-aos="fade-right" className='absolute -z-10 -left-2 desktop:-left-5 text-7xl desktop:text-9xl font-bold text-white/15 select-none'>EVENTS</h3>

            <div className='flex gap-7 desktop:gap-20 justify-center items-center my-10 desktop:my-24'>
                <Link data-aos="fade-up" to="/events" className='grid grid-cols-2 grid-rows-7 gap-1 desktop:gap-3 w-1/3 desktop:w-1/4 h-32 desktop:h-72'>
                    <div className='row-span-4'><img src={process.env.PUBLIC_URL + `/images/home-events/event-${images.img1}.jpg`} alt='Event' className={`rounded-xl w-full h-full object-cover`}/></div>
                    <div className='row-span-3'><img src={process.env.PUBLIC_URL + `/images/home-events/event-${images.img2}.jpg`} alt='Event' className={`rounded-xl w-full h-full object-cover`}/></div>
                    <div className='row-span-4'><img src={process.env.PUBLIC_URL + `/images/home-events/event-${images.img3}.jpg`} alt='Event' className={`rounded-xl w-full h-full object-cover`}/></div>
                    <div className='row-span-3'><img src={process.env.PUBLIC_URL + `/images/home-events/event-${images.img4}.jpg`} alt='Event' className={`rounded-xl w-full h-full object-cover`}/></div>
                </Link>
                <div className='flex flex-col gap-2 select-none'>
                    <h1 data-aos="fade-up" className='text-3xl desktop:text-8xl font-bold'>HIMSTAT'S</h1>
                    <h1 data-aos="fade-up" className='text-3xl desktop:text-8xl font-bold'>EVENTS</h1>
                    <Link data-aos="fade-up" to="/events" className='desktop:mt-4 w-fit'>
                        <p className="rounded-full px-4 py-2 text-sm desktop:px-10 desktop:py-2 desktop:text-xl bg-silver text-midnight transition-all hover:scale-105 font-semibold cursor-pointer">Click Here!</p>
                    </Link>
                </div>
            </div>
            
            <div className='flex flex-col justify-center items-center gap-10 mx-2.5 desktop:mx-16'>
                <h1 data-aos="fade-up" className='text-3xl desktop:text-8xl font-bold select-none'>ARTICLES</h1>
                {articleHighlights.length > 0 ?
                        <div className='flex justify-center font-sans'>
                            <Link data-aos="fade-up" to={`/articles/${articleHighlights[0].ArticleId}`} className='hidden desktop:flex flex-col gap-4 justify-center items-start p-6 bg-white text-midnight rounded-2xl w-2/5'>
                                <img src={process.env.REACT_APP_BASE_URL + "/storage/ArticleStorage/Thumbnail/" + articleHighlights[0].Thumbnail} alt="thumbnail" className='min-h-[550px] object-cover rounded-2xl'/>
                                <div className='flex flex-col gap-8 min-w-full'>
                                    <h5 className='text-2xl font-semibold'>{articleHighlights[0].Title}</h5>
                                    <div className='flex justify-between items-center'>
                                        <p>{articleHighlights[0].author.Name}</p>
                                        <p>{formatDate(articleHighlights[0].DateIn)}</p>
                                    </div>
                                </div>
                            </Link>
                            <div data-aos="fade-up" className='desktop:w-2/5 flex flex-col items-center gap-4 desktop:justify-between mx-2.5 desktop:mx-12 h-inherit'>
                                {articleHighlights.map((articleHighlight, index) => (
                                    <Link to={`/articles/${articleHighlight.ArticleId}`} key={index} className={`flex ${index === 0 ? 'desktop:hidden' : ''} bg-white text-midnight p-2.5 desktop:p-6 rounded-2xl gap-4 desktop:gap-6 min-w-full`}>
                                        <img src={process.env.REACT_APP_BASE_URL + "/storage/ArticleStorage/Thumbnail/" + articleHighlight.Thumbnail} alt="thumbnail" className='w-24 min-h-24 tablet:w-36 tablet:min-h-36 desktop:w-44 desktop:min-h-44 rounded-2xl object-cover'/>
                                        <div className='flex flex-col justify-between'>
                                            <div className='flex flex-col gap-1'>
                                                <h5 className='text-sm tablet:text-lg desktop:text-xl font-semibold'>{articleHighlight.Title}</h5>
                                                <p className='text-xs tablet:text-base desktop:text-lg font-medium'>{articleHighlight.author.Name}</p>
                                            </div>
                                            <p className='text-xs tablet:text-base desktop:text-lg font-medium'>{formatDate(articleHighlight.DateIn)}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div> :
                        <div data-aos="fade-up" className="text-white text-center">
                            <h2 className="text-xl font-semibold">No Articles Available</h2>
                            <p className="mt-4">It looks like there are no articles available at the moment. Check back later or explore other sections of our site.</p>
                        </div>
                    }
                <Link to="/articles" className='rounded-full px-6 py-2 desktop:px-10 text-sm desktop:text-xl bg-silver text-midnight transition-all hover:scale-105 font-semibold select-none cursor-pointer'>View All</Link>
            </div>
        </div>
    )
}